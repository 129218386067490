import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AcknowledgmentRequest, EntityTypeFieldDto } from '@shared/classes';
import { BaseRequestControllerService } from 'app/shared/services/api/base-request-controller.service';
import { DataService } from 'app/shared/services/api/data.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EntityTypesDataService extends BaseRequestControllerService<EntityTypeFieldDto> {
  constructor(
    dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.entity, '/entity-types', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'translations',
      'originalLanguage',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'name',
      'label',
      'icon',
      'category',
    ]);
  }
  navigateToListPage() {
    this.router.navigateByUrl(`entity/entity-type/list`);
  }
  entityModuleByType = {
    [EntityTypeFieldDto.CategoryEnum.Event]: AcknowledgmentRequest.TargetTypeEnum.Event,
    [EntityTypeFieldDto.CategoryEnum.Asset]: AcknowledgmentRequest.TargetTypeEnum.Asset,
    [EntityTypeFieldDto.CategoryEnum.Geography]: AcknowledgmentRequest.TargetTypeEnum.Geography,
    [EntityTypeFieldDto.CategoryEnum.Objective]: AcknowledgmentRequest.TargetTypeEnum.Objective,
    [EntityTypeFieldDto.CategoryEnum.Process]: AcknowledgmentRequest.TargetTypeEnum.Process,
    [EntityTypeFieldDto.CategoryEnum.Responsibility]: AcknowledgmentRequest.TargetTypeEnum.Responsibility,
  };
}
