/**
 * Sightflare Service
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CustomContentConfig { 
    componentType?: CustomContentConfig.ComponentTypeEnum;
    options?: any;
}
export namespace CustomContentConfig {
    export type ComponentTypeEnum = 'RISK_HEATMAP' | 'SYSTEM_TIMELINE' | 'NOTIFICATIONS';
    export const ComponentTypeEnum = {
        RiskHeatmap: 'RISK_HEATMAP' as ComponentTypeEnum,
        SystemTimeline: 'SYSTEM_TIMELINE' as ComponentTypeEnum,
        Notifications: 'NOTIFICATIONS' as ComponentTypeEnum
    };
}


