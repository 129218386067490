<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="effectiveTime"
  [dataType]="dataType"
  [label]="label"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <div class="field" [class.mb-1]="control?.dirty && this.control.errors">
    <span
      class="flex flex-row md:flex-nowrap flex-wrap gap-1 w-full algin-items-start"
      [class.p-float-label]="floatLabel"
      [class.p-input-icon-left]="leftIcon"
      [class.p-input-icon-right]="rightIcon"
    >
      @if (!floatLabel) {
        @if (label && !instanceInFormRepeater) {
          <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
            >{{ label }}
            @if (isControlRequired) {
              <span class="text-red-500"> *</span>
            }
            @if (resetFieldCheckVisable) {
              <br />
              <app-reset-switch-input
                [checked]="!resetable"
                (onChange)="setFieldResetable($event)"
              ></app-reset-switch-input>
            }
          </label>
        }
      }
      @if (leftIcon) {
        <i [class]="' pi ' + leftIcon"></i>
      }
      @if (rightIcon) {
        <i [class]="' pi ' + rightIcon"></i>
      }
      @if (inputMask) {
        <p-inputMask
          class="w-full"
          [name]="name || label"
          [id]="name || label"
          [formControl]="fControl"
          [placeholder]="placeholder"
          [mask]="inputMask"
          (onFocus)="onFocusChange($event)"
        ></p-inputMask>
      } @else {
        <div class="w-full gap-2 flex-col">
          @if (viewMode != 'view') {
            <input
              appMaxCharacterValidator
              pInputText
              class="w-full"
              [type]="type"
              [name]="name || label"
              [id]="name || label"
              [formControl]="timeStringFControl"
              [class.ng-invalid]="timeStringFControl?.invalid"
              [class.ng-dirty]="timeStringFControl?.dirty"
              [placeholder]="placeholder"
              (focus)="onFocusChange($event)"
              [readOnly]="readOnly"
            />
          }
          <app-view-switch
            class="w-full text-center my-auto p-inputtext"
            style="background: none"
            [viewType]="dataType"
            [data]="effectiveTime"
            [options]="timeViewOptions"
          ></app-view-switch>
        </div>
      }

      @if (floatLabel) {
        @if (label && !instanceInFormRepeater) {
          <label [for]="name || label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
            >{{ label }}
            @if (isControlRequired) {
              <span class="text-red-500"> *</span>
            }
            @if (resetFieldCheckVisable) {
              <br />
              <app-reset-switch-input
                [checked]="!resetable"
                (onChange)="setFieldResetable($event)"
              ></app-reset-switch-input>
            }
          </label>
        }
      }
    </span>
    @if (control?.dirty) {
      @if (control?.hasError('required')) {
        <small class="p-error p-invalid">{{ label }} {{ 'is Required' }}</small>
      }
      @if (control?.hasError('email')) {
        <small class="p-error p-invalid">Enter a Valid Email</small>
      }
      @if (control?.hasError('pattern')) {
        <small class="p-error p-invalid">{{ label }} {{ patternError }}</small>
      }
      @if (control?.hasError('minlength')) {
        <small class="p-error p-invalid">
          {{ label }} must have a minimum length of {{ control?.errors?.minlength?.requiredLength }}
        </small>
      }
      @if (control?.hasError('maxlength')) {
        <small class="p-error p-invalid">
          {{ label }} must have a maximum length of {{ control?.errors?.maxlength?.requiredLength }}
        </small>
      }
      @if (control?.hasError('min')) {
        <small class="p-error p-invalid">
          {{ label }} must be greater than or equal to {{ control?.errors?.min?.min }}
        </small>
      }
      @if (control?.hasError('max')) {
        <small class="p-error p-invalid">
          {{ label }} must be less than or equal to {{ control?.errors?.max?.max }}
        </small>
      }
      @if (customError && control?.invalid) {
        <small class="p-error p-invalid">{{ customErrorLabel }}</small>
      }
    }
    @if (timeStringFControl?.dirty) {
      @if (timeStringFControl?.hasError('pattern')) {
        <small class="p-error p-invalid">{{ label }} {{ patternError }}</small>
      }
    }
    @if (helpText) {
      <small class="text-500 block">{{ helpText }}</small>
    }
  </div>
</app-input-view-switch>
