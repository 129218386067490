<input
  appMaxCharacterValidator
  type="file"
  (change)="fileChangeEvent($event)"
  #fileInput
  style="display: none"
  [multiple]="multiple"
  [accept]="accept"
/>
@if (popupMode) {
  <p-button class="w-fit block" (click)="showDialog()" icon="pi pi-image" [label]="'Change ' + label"></p-button>
}
@if (popupMode) {
  <p-dialog
    [header]="label"
    [(visible)]="displayDialog"
    [modal]="true"
    [style]="{ width: '80vw' }"
    [maximizable]="true"
    [draggable]="false"
    [resizable]="false"
  >
    <ng-container *ngTemplateOutlet="imageCropperTemplate"></ng-container>
    <ng-template pTemplate="footer">
      <!-- <p-button icon="pi pi-check" (click)="displayDialog=false" label="Ok" styleClass="p-button-text"></p-button> -->
      @if (viewMode != 'view') {
        <div class="w-full flex align-items-end justify-content-end gap-2 py-0 px-3">
          <button pButton type="button" label="Browse" (click)="fileInput.click()"></button>
          <!-- <p-fileUpload [mode]="mode" name="documentUrl[]" [styleClass]="'px-3'" [chooseLabel]="'Browse'"
          [multiple]="multiple" [accept]="accept" [maxFileSize]="maxFileSize" [fileLimit]="fileLimit"
          (uploadHandler)="onBasicUpload($event)" [customUpload]="true" (onSelect)="onSelectFile($event)"
        #fileBrowser></p-fileUpload> -->
          <!-- <app-button *ngIf="showCancelFile" [action]="cancelFileSelectionAction"
      [commandData]="fileBrowser"></app-button> -->
        </div>
      }
    </ng-template>
  </p-dialog>
}
@if (!popupMode) {
  <ng-container *ngTemplateOutlet="imageCropperTemplate"></ng-container>
  <!-- <div *ngIf="viewMode != 'view'" class="w-full flex align-items-end justify-content-end gap-2 py-0 px-3">
  <button pButton type="button" label="Browse" (click)="fileInput.click()"></button> -->
  <!-- <p-fileUpload [mode]="mode" name="documentUrl[]" [styleClass]="'px-3'" [chooseLabel]="'Browse'"
  [multiple]="multiple" [accept]="accept" [maxFileSize]="maxFileSize" [fileLimit]="fileLimit"
  (uploadHandler)="onBasicUpload($event)" [customUpload]="true" (onSelect)="onSelectFile($event)"
#fileBrowser></p-fileUpload>
<app-button *ngIf="showCancelFile" [action]="cancelFileSelectionAction"
[commandData]="fileBrowser"></app-button> -->
  <!-- </div> -->
}
<ng-template #imageCropperTemplate>
  <div class="w-full grid p-fluid m-auto">
    <div class="w-full p-0">
      <app-input-view-switch
        [showLabelInViewMode]="showLabelInViewMode"
        [mode]="viewMode"
        [data]="data || control?.value"
        [dataType]="dataType"
        [label]="label"
      >
        <div class="grid">
          <!-- <app-basic-input [label]="label" [placeholder]="'Enter Document Url'" [control]="fControl"
        [viewMode]="viewMode"></app-basic-input> -->
          <div class="col-12 md:col max-h-20rem h-20rem">
            @if (viewMode != 'view') {
              <div
                id="file-drop-zone"
                class="border-2 border-dashed border-round surface-border max-h-full h-full flex flex-column gap-3 justify-content-center align-items-center cursor-pointer text-center hover:bg-black-alpha-10 relative"
                [class.bg-black-alpha-10]="isDragOverTarget"
                (click)="fileInput.click()"
              >
                <i class="pi pi-cloud-upload text-7xl pointer-events-disabled select-none text-400"></i>
                @if (!isDragOverTarget) {
                  <p class="text-2xl pointer-events-disabled select-none text-400">
                    Drag and drop here or <span class="text-primary">Browse</span>
                  </p>
                }
                @if (isDragOverTarget) {
                  <p class="text-2xl pointer-events-disabled select-none text-400">Drop Here!</p>
                }
                <div
                  class="absolute top-0 left-0 h-full w-full z-2"
                  (dragover)="dragOver($event)"
                  (dragleave)="dragLeave($event)"
                  (drop)="drop($event)"
                ></div>
              </div>
            }
          </div>
          @if (imageChangedEvent || data) {
            <div class="col-12 md:col max-h-20rem">
              <image-cropper
                class="transparency-bg h-full"
                [imageChangedEvent]="imageChangedEvent"
                [imageBase64]="data"
                [maintainAspectRatio]="maintainAspectRatio"
                [aspectRatio]="aspectRatio"
                [format]="format"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded($event)"
                (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()"
                [output]="outputMode"
              ></image-cropper>
            </div>
          }
          @if ((imageChangedEvent || data) && croppedImage) {
            <div class="col-12 md:col max-h-20rem">
              <div class="w-full h-full transparency-bg">
                <img class="w-full h-full" style="object-fit: contain" [src]="croppedImage" />
              </div>
            </div>
          }
          @if ((imageChangedEvent || data) && viewMode != 'view') {
            <div class="flex flex-row w-12 justify-content-center">
              <!-- <div *ngIf="viewMode != 'view'" class="flex align-items-end justify-content-end gap-2 py-0 px-2"
            [class.w-12]="!(imageChangedEvent || data)"
            [class.w-2]="(imageChangedEvent || data)"
            >
            <button pButton type="button" label="Browse" icon="pi pi-cloud-upload" [iconPos]="'right'" (click)="fileInput.click()"></button>
          </div> -->
              @if (imageChangedEvent || data) {
                <div class="w-fit flex align-items-end justify-content-end gap-2 py-0 px-2">
                  <button
                    pButton
                    class="p-button-danger"
                    type="button"
                    label="Remove"
                    icon="pi pi-trash"
                    [iconPos]="'right'"
                    (click)="clearData()"
                  ></button>
                </div>
              }
            </div>
          }
        </div>
      </app-input-view-switch>
    </div>
  </div>
</ng-template>
