/**
 * Sightflare Service
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RuleHandler { 
    type?: RuleHandler.TypeEnum;
}
export namespace RuleHandler {
    export type TypeEnum = 'STATE_MACHINE_EVENT' | 'ACTION' | 'FORM_EDIT_VALIDATOR' | 'FIELD_EDIT_VALIDATOR';
    export const TypeEnum = {
        StateMachineEvent: 'STATE_MACHINE_EVENT' as TypeEnum,
        Action: 'ACTION' as TypeEnum,
        FormEditValidator: 'FORM_EDIT_VALIDATOR' as TypeEnum,
        FieldEditValidator: 'FIELD_EDIT_VALIDATOR' as TypeEnum
    };
}


