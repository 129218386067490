import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ApiResponseLanguageEvent, BaseResponse, isNullObj, LanguageDto, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerWithRuleService, DataService, ExportDataService } from '@shared/services';
import { NgxCsvParser } from 'ngx-csv-parser';
import { firstValueFrom, forkJoin, Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageDataService extends BaseRequestControllerWithRuleService<
  LanguageDto,
  ApiResponseLanguageEvent.DataEnum
> {
  private downloadLanguageFileUrl: string = '/download-language-file/';
  private readonly makeAsDefaultUrl: string = '/make-as-default';
  private readonly getDefaultLanguageUrl: string = '/default-language';
  constructor(
    private dataService: DataService,
    private router: Router,
    private ngxCsvParser: NgxCsvParser,
    private exportDataService: ExportDataService
  ) {
    super(dataService, environment.dataStructure, '/languages', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'translations',
      'originalLanguage',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'name',
      // 'description',
      'langCode',
      'status',
      'direction',
      'icon',
      'langFile',
    ]);
  }

  navigateToListPage() {
    this.router.navigateByUrl(`data-management/language/list`);
  }
  downloadLanguageFileAsTextResponse(fileId, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getTextData(
      `${this.url}${this.downloadLanguageFileUrl}${fileId}`,
      options
    ) as Observable<any>;
  }
  downloadLocalLanguageFile(options: RequestHandlerOptions = new RequestHandlerOptions()) {
    const fileName = 'translation_template.csv';
    return this.dataService.getTextData(`assets/public/${fileName}`, options) as Observable<any>;
  }
  // downloadLanguageFile(fileId: string, params = null) {
  //   return this.dataService.download(`${this.url}${this.downloadLanguageFileUrl}${fileId}`, params);
  // }
  downloadFileWithProgress(fileId: string, downloadFileUrl = this.downloadLanguageFileUrl, params = null) {
    return this.dataService.downloadWithProgress(`${this.url}${downloadFileUrl}${fileId}`, params);
  }
  async downloadMergedCsv(lang: string): Promise<any> {
    // const languageRequestService: LanguageDataService = AppInjector.get(LanguageDataService);
    const fileName = 'translation_template.csv';

    const langs = await firstValueFrom(
      forkJoin({
        localLang: this.downloadLocalLanguageFile(),
        lang: this.downloadLanguageFileAsTextResponse(lang),
      })
    );
    // const localJsonCsv = this.convertToJsonCSV(langs.localLang);
    const csvFileLocal = new File([new Blob([langs.localLang], { type: 'text/csv' })], 'local.csv', {
      type: 'text/csv',
    });
    const csvFile = new File([new Blob([langs.lang], { type: 'text/csv' })], 'lang.csv', { type: 'text/csv' });

    const localLangData = await this.parseCsvFile(csvFileLocal);
    const langData = await this.parseCsvFile(csvFile);

    const lastMerge = this.mergeCsvData(localLangData, langData);
    console.log('lastMerge', lastMerge);
    this.exportDataService.exportCSV(lastMerge, 'language');
  }

  parseCsvFile(file: File): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.ngxCsvParser.parse(file, { header: false, delimiter: ',', encoding: 'utf8' }).subscribe({
        next: (result: any[]) => {
          resolve(result);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }
  parseCsvText(csvText: any): Promise<any> {
    const csvBlob = new Blob([csvText], { type: 'text/csv' });
    const csvFile = new File([csvBlob], 'data.csv', { type: 'text/csv' });

    return new Promise((resolve, reject) => {
      this.ngxCsvParser
        .parse(csvFile, { header: false, delimiter: ',', encoding: 'utf8' })
        .pipe()
        .subscribe({
          next: (result): void => {
            resolve(result); // Resolve the promise with the result
          },
          error: (err): void => {
            reject(err); // Reject the promise in case of an error
          },
        });
    });
  }
  mergeCsvData(doc1Data: any[], doc2Data: any[]): any[] {
    const mergedData = [];
    const doc2Keys = new Set(doc2Data.map((row) => row[0])); // Assuming the first column is the key
    const docKeys = new Set<string>();
    doc2Data.forEach((row) => {
      if (!docKeys.has(row[0])) {
        mergedData.push(row);
        docKeys.add(row[0]);
      }
    });

    doc1Data.forEach((row) => {
      if (!doc2Keys.has(row[0])) {
        mergedData.push(row);
      }
    });

    return mergedData;
  }
  makeAsDefault(code: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData(`${this.url}/${code}${this.makeAsDefaultUrl}`, null, options);
  }
  getDefaultLanguage(options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<LanguageDto>(`${this.url}${this.getDefaultLanguageUrl}`, options) as Observable<
      BaseResponse<LanguageDto>
    >;
  }
  downloadLocalLanguageFileAsJsonResponse(options: RequestHandlerOptions = new RequestHandlerOptions()) {
    const fileName = 'en.json';
    return this.dataService.getData<any>(`assets/public/${fileName}`, options) as Observable<any>;
  }

  flattenObject(obj: any, parent: string = '', res: any = {}) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const propName = parent ? `${parent}.${key}` : key;
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          this.flattenObject(obj[key], propName, res);
        } else {
          res[propName] = obj[key];
        }
      }
    }
    return res;
  }
  convertToJsonCSV(json: any): string {
    const flattenedJson = this.flattenObject(json);
    const csvRows: string[] = ['Key,Value,Translation'];
    for (const key in flattenedJson) {
      if (flattenedJson.hasOwnProperty(key)) {
        csvRows.push(`${key},${flattenedJson[key]},`);
      }
    }
    return csvRows.join('\n');
  }
  loadingAllLanguagesState: boolean | 'error' = false;
  allLanguages: LanguageDto[] = [];
  private allLanguagesSubject: ReplaySubject<LanguageDto[]> = new ReplaySubject(1);
  downloadAllLanguagesWithFiles(): Observable<any> {
    if (!isNullObj(this.allLanguages)) {
      // If cached value exists, emit it directly
      this.allLanguagesSubject.next(this.allLanguages);
    } else if (this.loadingAllLanguagesState !== true) {
      this.loadingAllLanguagesState = true;
      this.search(
        { all: true, page: 0 },
        {},
        {
          extras: { cacheKey: 'allLanguages' },
          showLoading: false,
          showMsg: false,
        }
      ).subscribe({
        next: (res) => {
          this.allLanguages = res as any;
          this.allLanguagesSubject.next(this.allLanguages); // Emit the result to the subject
          this.loadingAllLanguagesState = false;
        },
        error: (err) => {
          this.loadingAllLanguagesState = 'error';
          console.error('Languages Download Failed');
        },
      });
    }
    return this.allLanguagesSubject.asObservable(); // Return the observable
  }
}
