import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataTypeEnum } from '@shared/classes';
import { DynamicComponentBase } from '@shared/classes/view/DynamicComponentBase';
import { FieldTranslationsOptions } from '@shared/components/components/side-bar-languages/field-translations-button/field-translations-button.component';
import { PathResolverService } from '@shared/services';

@Component({
  selector: 'app-basic-input',
  templateUrl: './basic-input.component.html',
  styleUrls: ['./basic-input.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: BasicInputComponent }],
})
export class BasicInputComponent extends DynamicComponentBase implements OnInit {
  dataType: DataTypeEnum = DataTypeEnum.Text;
  @Output() onFocus: EventEmitter<any> = new EventEmitter();
  itemCode: string;

  constructor(
    private route: ActivatedRoute,
    private pathResolverService: PathResolverService
  ) {
    super();
    this.route.params.subscribe({
      next: (params) => {
        this.itemCode = params['id'];
      },
    });
  }

  ngOnInit(): void {}
  setInputOptions() {}
  onFocusChange(event) {
    this.onFocus.emit(event);
  }
  get translationOptions(): FieldTranslationsOptions {
    return {
      label: this.label,
      placeholder: this.placeholder,
      textFieldType: DataTypeEnum.Text,
      fieldKey: this.getControlName(),
      itemCode: this.itemCode,
      formData: this.control.value,
      dataService: this.itemCode ? this.pathResolverService.getDataServiceByCode(this.itemCode) : null,
      mappingService: this.itemCode
        ? this.pathResolverService.getMappingServiceByModuleKeyword(
            this.pathResolverService.getModuleKeywordByCode(this.itemCode)
          )
        : null,
      options: null,
    };
  }
}
