import { AfterViewInit, Directive, Type } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JWTTokenService } from '@core/services/JWT-token/jwttoken.service';
import { LocaleService } from '@core/services/locale/locale.service';
import { BaseFormPopupComponent } from '@shared/components/misc/base-form-popup/base-form-popup.component';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { ExportDataService } from '@shared/services/export-data.service';
import { ToastService } from '@shared/services/toast.service';
import { AppInjector } from 'app/app-injector';
import { RequestHandlerOptions } from '../controller';
import { getDataKeyValueFormat, humanizeCasedString, isNullObj, toKebabCase } from '../helpers';
import { AcknowledgmentDto, FilterItem } from '../model';
import { PermissionActions } from './AppPermissions.enum';
import { IColumn, ITableLoadEvent } from './AppTable';
import { BasePage } from './BasePage';
import { ButtonColor, IAction } from './ButtonAction';
import { IExportData } from './IExportData';
import { PageStaticConfigParams } from './PageConfigParams';
import { IViewMode } from './view-enums';
// import { ExportGlobalPackageDataService } from "../../../modules/global-package-module/services/data/export-global-package-data.service";
@Directive()
export abstract class BaseTablePage<T> extends BasePage implements AfterViewInit {
  cols: IColumn[] = [];
  tableActions: IAction[] = [];
  actionCol: IColumn = {
    name: 'general.actions.actions',
    key: 'actions',
    dataType: null,
    frozen: true,
    alignFrozen: 'right',
  };
  tableData: T[] = [];
  exportDataItems: T[] = [];
  filters: any = {};
  tableEvent: ITableLoadEvent = new ITableLoadEvent();
  editDialogComponent: Type<any> = null;
  additionalStaticFilters: FilterItem[] = [];
  sessionStorageKey: string = 'table-state-session';
  private _reqService: BaseRequestControllerService<T>;
  set reqService(reqService: BaseRequestControllerService<T>) {
    this._reqService = reqService;
    //    this.loadDataWrapper();
  }
  get reqService() {
    return this._reqService;
  }
  exportPackageTypesToInclude: string[] = ['CITATION_TYPE', 'CITATION', 'AUTHORITY_DOCUMENT'];
  isLoading: boolean = false;
  initialTableLoaded = 0;
  showDeleteAction: boolean = true;
  showRestoreDeleteAction: boolean = true;
  showEditAction: boolean = true;
  showStatusActions: boolean = true;
  reflectFiltersInUrl: boolean = true;
  importerAction: IAction = {
    label: 'Import (Beta)',
    id: 100,
    color: ButtonColor.Primary,
    icon: 'pi pi-plus',
    iconPos: 'left',
    command: this.importItems.bind(this),
    permission: `${PermissionActions.Create}${this.moduleKeyword}`,
  };
  constructor(
    reqService: BaseRequestControllerService<T>,
    private exportService: ExportDataService,
    public appDialogService: AppDialogService,
    public router: Router,
    pageConfig: PageStaticConfigParams = new PageStaticConfigParams(),
    public localeService: LocaleService,
    public pageMode: 'NORMAL' | 'DELETED' = 'NORMAL'
  ) {
    super(pageConfig);
    this.reqService = reqService;
    this.sessionStorageKey = `${toKebabCase(this.moduleKeyword)}-table`;
    this.setCols();
    this.initializeTableActions();
  }
  ngAfterViewInit(): void {
    // this.setTableActions();
    this.afterViewInit();
    // const activatedRoute = AppInjector.get(ActivatedRoute);
    // activatedRoute.queryParams.subscribe({next:(queryParams)=>{
    //     // const parsedUrl = this.router.parseUrl(window.location.pathname + window.location.search);
    //     let parsedFilters = [];
    //     try {
    //         parsedFilters = queryParams?.['filters'] ? JSON.parse(atob(decodeURIComponent(queryParams?.['filters']))) : [];
    //     } catch (error) {
    //         console.error('PARSING FILTERS FAILED')
    //     }
    //     this.tableEvent = {
    //         ...this.tableEvent,
    //         filters:isNullObj(parsedFilters) ? this.tableEvent.filters : parsedFilters,
    //         pageInfo:{
    //             ...this.tableEvent.pageInfo,
    //             pagination:{
    //                 ...this.tableEvent.pageInfo.pagination,
    //                 page:isNullObj(queryParams?.['page']) ? this.tableEvent?.pageInfo?.pagination?.page : queryParams?.['page'],
    //                 size:isNullObj(queryParams?.['size']) ? this.tableEvent?.pageInfo?.pagination?.size : queryParams?.['size'],
    //                 sort:isNullObj(queryParams?.['sort']) ? this.tableEvent?.pageInfo?.pagination?.sort : JSON.parse(decodeURIComponent(queryParams?.['sort'])),
    //                 // sortDir:isNullObj(queryParams?.['sortDir']) ? this.tableEvent?.pageInfo?.pagination?.sortDir : queryParams?.['sortDir'],
    //             },
    //         },
    //     };
    // }})
  }
  abstract setCols(): any;

  // abstract setTableActions(): any;
  // abstract onFilterChange(tableEvent:ITableLoadEvent): any;
  // abstract loadData(): any;
  // abstract deleteItem(row: T): any;
  // abstract editItem(row: T): any;
  // abstract addNewItem(row: T): any;

  setTableActions() {}
  initializeTableActions() {
    this.setTableActions();
    const jwtTokenService = AppInjector.get(JWTTokenService);
    if (!this.tableActions) this.tableActions = [];
    const baseTableActions: IAction[] = [
      // {
      //     id: 9999,
      //     icon: 'pi pi-eye',
      //     // buttonStyle: 'text',
      //     color: ButtonColor.Secondary,
      //     command: this.viewItem.bind(this),
      //     permission: `${PermissionActions.Read}${this.moduleKeyword}`,
      //     // tooltipText: 'View',
      //     // tooltipOptions: {
      //     //     tooltipLabel: "View"
      //     // },
      //     group:{id:1,type:'split'},
      //     label:'View'
      // },
      {
        id: 9998,
        icon: 'pi pi-pencil',
        iconPos: 'left',
        buttonClass: 'w-full',
        // buttonStyle: 'text',
        color: ButtonColor.Secondary,
        command: this.editItem.bind(this),
        permission: `${PermissionActions.Update}${this.moduleKeyword}`,
        // tooltipText: 'Edit',
        // tooltipOptions: {
        //     tooltipLabel: "Edit"
        // },
        displayCommand: (data: AcknowledgmentDto) =>
          this.showStatusActions &&
          this.showEditAction &&
          (!data?.recordStatus || (data?.recordStatus && data?.recordStatus == 'ACTIVE')) &&
          (!data?.lockedForEdit || data?.lockedBy == jwtTokenService.getPreferredUsername()) &&
          (jwtTokenService.getSfAdmin() ||
            !data?.userAccessLevel ||
            data?.userAccessLevel?.accessLevel == 'OWNER' ||
            data?.userAccessLevel?.accessLevel == 'ADMIN' ||
            data?.userAccessLevel?.accessLevel == 'UPDATE'),
        group: { id: 1, type: 'split' },
        label: 'Edit',
      },
      {
        id: 9997,
        icon: 'pi pi-copy',
        iconPos: 'left',
        buttonClass: 'w-full',
        // buttonStyle: 'text',
        color: ButtonColor.Secondary,
        command: this.copyItem.bind(this),
        permission: `${PermissionActions.Create}${this.moduleKeyword}`,
        tooltipText: 'Create Clone Item',
        tooltipOptions: {
          tooltipLabel: 'Create Clone Item',
        },
        group: { id: 1, type: 'split' },
        label: 'Clone',
      },
      {
        id: 9996,
        icon: 'pi pi-lock',
        iconPos: 'left',
        buttonClass: 'w-full',
        // buttonStyle: 'text',
        color: ButtonColor.Secondary,
        command: this.lockItem.bind(this),
        permission: `${PermissionActions.Delete}${this.moduleKeyword}`,
        // tooltipText: 'Lock',
        // tooltipOptions: {
        //     tooltipLabel: "Lock"
        // },
        displayCommand: (data: AcknowledgmentDto) =>
          this.showStatusActions &&
          data?.recordStatus &&
          data?.recordStatus == 'ACTIVE' &&
          (!data?.lockedForEdit || data?.lockedBy == jwtTokenService.getPreferredUsername()) &&
          (jwtTokenService.getSfAdmin() ||
            !data?.userAccessLevel ||
            data?.userAccessLevel?.accessLevel == 'OWNER' ||
            data?.userAccessLevel?.accessLevel == 'ADMIN'),
        group: { id: 1, type: 'split' },
        label: 'Lock',
      },
      {
        id: 9995,
        icon: 'pi pi-inbox',
        iconPos: 'left',
        buttonClass: 'w-full',
        // buttonStyle: 'text',
        color: ButtonColor.Secondary,
        command: this.archiveItem.bind(this),
        permission: `${PermissionActions.Delete}${this.moduleKeyword}`,
        // tooltipText: 'Archive',
        // tooltipOptions: {
        //     tooltipLabel: "Archive"
        // },
        displayCommand: (data: AcknowledgmentDto) =>
          this.showStatusActions &&
          data?.recordStatus &&
          data?.recordStatus == 'ACTIVE' &&
          (!data?.lockedForEdit || data?.lockedBy == jwtTokenService.getPreferredUsername()) &&
          (jwtTokenService.getSfAdmin() ||
            !data?.userAccessLevel ||
            data?.userAccessLevel?.accessLevel == 'OWNER' ||
            data?.userAccessLevel?.accessLevel == 'ADMIN'),
        group: { id: 1, type: 'split' },
        label: 'Deactivate',
      },
      {
        id: 9994,
        icon: 'pi pi-upload',
        iconPos: 'left',
        buttonClass: 'w-full',
        // buttonStyle: 'text',
        color: ButtonColor.Secondary,
        command: this.restoreItem.bind(this),
        permission: `${PermissionActions.Delete}${this.moduleKeyword}`,
        // tooltipText: 'Restore to Active',
        // tooltipOptions: {
        //     tooltipLabel: "Restore to Active"
        // },
        displayCommand: (data: AcknowledgmentDto) =>
          this.showStatusActions &&
          data?.recordStatus &&
          data?.recordStatus != 'ACTIVE' &&
          data?.recordStatus == 'IN_ACTIVE' &&
          (!data?.lockedForEdit || data?.lockedBy == jwtTokenService.getPreferredUsername()) &&
          (jwtTokenService.getSfAdmin() ||
            !data?.userAccessLevel ||
            data?.userAccessLevel?.accessLevel == 'OWNER' ||
            data?.userAccessLevel?.accessLevel == 'ADMIN'),
        group: { id: 1, type: 'split' },
        label: 'Activate',
      },
      {
        id: 9993,
        icon: 'pi pi-unlock',
        iconPos: 'left',
        buttonClass: 'w-full',
        // buttonStyle: 'text',
        color: ButtonColor.Secondary,
        command: this.restoreItem.bind(this),
        permission: `${PermissionActions.Delete}${this.moduleKeyword}`,
        // tooltipText: 'Restore to Active',
        // tooltipOptions: {
        //     tooltipLabel: "Restore to Active"
        // },
        displayCommand: (data: AcknowledgmentDto) =>
          this.showStatusActions &&
          data?.recordStatus &&
          data?.recordStatus != 'ACTIVE' &&
          data?.recordStatus == 'LOCKED' &&
          (!data?.lockedForEdit || data?.lockedBy == jwtTokenService.getPreferredUsername()) &&
          (jwtTokenService.getSfAdmin() ||
            !data?.userAccessLevel ||
            data?.userAccessLevel?.accessLevel == 'OWNER' ||
            data?.userAccessLevel?.accessLevel == 'ADMIN'),
        group: { id: 1, type: 'split' },
        label: 'Unlock',
      },
      {
        id: 9992,
        icon: 'pi pi-trash',
        iconPos: 'left',
        buttonClass: 'w-full',
        // buttonStyle: 'text',
        color: ButtonColor.Secondary,
        command: this.deleteItem.bind(this),
        permission: `${PermissionActions.Delete}${this.moduleKeyword}`,
        // tooltipText: 'Delete',
        // tooltipOptions: {
        //     tooltipLabel: "Delete"
        // },
        displayCommand: (data: AcknowledgmentDto) =>
          data?.recordStatus &&
          data?.recordStatus != 'DELETED' &&
          this.showStatusActions &&
          this.showDeleteAction &&
          (!data?.lockedForEdit || data?.lockedBy == jwtTokenService.getPreferredUsername()) &&
          (jwtTokenService.getSfAdmin() ||
            !data?.userAccessLevel ||
            data?.userAccessLevel?.accessLevel == 'OWNER' ||
            data?.userAccessLevel?.accessLevel == 'ADMIN'),
        group: { id: 1, type: 'split' },
        label: 'Delete',
      },
      {
        id: 9991,
        icon: 'pi pi-sync',
        iconPos: 'left',
        buttonClass: 'w-full',
        // buttonStyle: 'text',
        color: ButtonColor.Secondary,
        command: this.restoreDeletedItem.bind(this),
        permission: `${PermissionActions.Delete}${this.moduleKeyword}`,
        // tooltipText: 'Delete',
        // tooltipOptions: {
        //     tooltipLabel: "Delete"
        // },
        displayCommand: (data: AcknowledgmentDto) =>
          data?.recordStatus &&
          data?.recordStatus == 'DELETED' &&
          this.showStatusActions &&
          this.showRestoreDeleteAction &&
          (!data?.lockedForEdit || data?.lockedBy == jwtTokenService.getPreferredUsername()) &&
          (jwtTokenService.getSfAdmin() ||
            !data?.userAccessLevel ||
            data?.userAccessLevel?.accessLevel == 'OWNER' ||
            data?.userAccessLevel?.accessLevel == 'ADMIN'),
        group: { id: 1, type: 'split' },
        label: 'Restore',
      },
      {
        id: 9990,
        icon: 'pi pi-trash',
        iconPos: 'left',
        buttonClass: 'w-full',
        // buttonStyle: 'text',
        color: ButtonColor.Secondary,
        command: this.hardDeleteItem.bind(this),
        permission: `${PermissionActions.Delete}${this.moduleKeyword}`,
        // tooltipText: 'Delete',
        // tooltipOptions: {
        //     tooltipLabel: "Delete"
        // },
        displayCommand: (data: AcknowledgmentDto) =>
          data?.recordStatus &&
          data?.recordStatus == 'DELETED' &&
          this.showStatusActions &&
          this.showRestoreDeleteAction &&
          (!data?.lockedForEdit || data?.lockedBy == jwtTokenService.getPreferredUsername()) &&
          (jwtTokenService.getSfAdmin() ||
            !data?.userAccessLevel ||
            data?.userAccessLevel?.accessLevel == 'OWNER' ||
            data?.userAccessLevel?.accessLevel == 'ADMIN'),
        group: { id: 1, type: 'split' },
        label: 'Permenant Delete',
      },
    ];
    this.tableActions = baseTableActions.concat(this.tableActions);
  }
  copyItem(row: T): any {
    this.router.navigateByUrl(
      `${this.routePrefix}${toKebabCase(this.moduleKeyword)}/create/clone/${(row as any).code || (row as any).id}`
    );
  }
  editItem(row: T): any {
    this.router.navigateByUrl(
      `${this.routePrefix}${toKebabCase(this.moduleKeyword)}/edit/${(row as any).code || (row as any).id}`
    );
  }
  viewItem(row: T): any {
    this.router.navigateByUrl(
      `${this.routePrefix}${toKebabCase(this.moduleKeyword)}/view/${(row as any).code || (row as any).id}`
    );
  }
  addNewItem(): any {
    this.router.navigateByUrl(`${this.routePrefix}${toKebabCase(this.moduleKeyword)}/create`);
  }
  importItems(): any {
    this.router.navigateByUrl(`${this.routePrefix}${toKebabCase(this.moduleKeyword)}/import`);
  }
  loadDataWrapper() {
    this.loadData();
  }
  loadData() {
    this.isLoading = this.tableEvent.showLoader;
    const requestOptions = new RequestHandlerOptions();
    const searchBody = {
      pageOptions: { ...this.tableEvent.pageInfo.pagination },
      searchBody: {
        filters: this.tableEvent.filters.concat(this.additionalStaticFilters),
        projectionFields: [...this.reqService.projectionFields, 'searchScore'],
        textSearch: this.tableEvent.textSearch,
      },
      options: { ...requestOptions, showLoading: this.tableEvent.showLoader },
    };
    this.subs.unsubscribe();
    this.subs.sink = (
      this.pageMode == 'NORMAL'
        ? this.reqService.search<T>(searchBody.pageOptions, searchBody.searchBody, searchBody.options)
        : this.reqService.searchDeleted(searchBody.pageOptions, searchBody.searchBody, searchBody.options)
    ).subscribe({
      next: (res: any) => {
        this.tableData = res.content;
        this.tableEvent.pageInfo.totalElements = res.totalElements;
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
      },
      complete: () => {},
    });
  }

  onFilterChange(tableEvent: ITableLoadEvent) {
    if (this.tableEvent.showLoader && this.initialTableLoaded > 2 && this.reflectFiltersInUrl) {
      const activatedRoute = AppInjector.get(ActivatedRoute);
      this.router.navigate([], {
        relativeTo: activatedRoute,
        queryParams: {
          filters: encodeURIComponent(btoa(JSON.stringify(this.getTableExistingFilters(this.tableEvent)))),
          page: this.tableEvent?.pageInfo?.pagination?.page,
          size: this.tableEvent?.pageInfo?.pagination?.size,
          sort: encodeURIComponent(JSON.stringify(this.tableEvent?.pageInfo?.pagination?.sort)),
          textSearch: this.tableEvent?.textSearch?.search,
          // sortDir:this.tableEvent?.pageInfo?.pagination?.sortDir,
        },
        queryParamsHandling: 'merge',
      });
      // const locationService = AppInjector.get(Location);
      // locationService.go(window.location.pathname,`?filters=${encodeURIComponent(btoa(JSON.stringify(this.tableEvent.filters)))}&page=${this.tableEvent.pageInfo.pagination.page}&size=${this.tableEvent.pageInfo.pagination.size}&sort=${this.tableEvent.pageInfo.pagination.sort}&sortDir=${this.tableEvent.pageInfo.pagination.sortDir}`);
    }
    // const parsedUrl = this.router.parseUrl(window.location.pathname + window.location.search);
    // let parsedFilters = [];
    // try {
    //     parsedFilters = parsedUrl?.queryParams?.['filters'] ? JSON.parse(atob(decodeURIComponent(parsedUrl?.queryParams?.['filters']))) : [];
    // } catch (error) {
    //     console.error('PARSING FILTERS FAILED')
    // }

    // this.tableEvent = {
    //     ...tableEvent,
    //     filters:isNullObj(parsedFilters) ? tableEvent.filters : parsedFilters,
    //     pageInfo:{
    //         ...tableEvent.pageInfo,
    //         pagination:{
    //             ...tableEvent.pageInfo.pagination,
    //             page:isNullObj(parsedUrl?.queryParams?.['page']) ? tableEvent?.pageInfo?.pagination?.page : parsedUrl?.queryParams?.['page'],
    //             size:isNullObj(parsedUrl?.queryParams?.['size']) ? tableEvent?.pageInfo?.pagination?.size : parsedUrl?.queryParams?.['size'],
    //             sort:isNullObj(parsedUrl?.queryParams?.['sort']) ? tableEvent?.pageInfo?.pagination?.sort : parsedUrl?.queryParams?.['sort'],
    //             sortDir:isNullObj(parsedUrl?.queryParams?.['sortDir']) ? tableEvent?.pageInfo?.pagination?.sortDir : parsedUrl?.queryParams?.['sortDir'],
    //         },
    //     },
    // };
    this.tableEvent = tableEvent;
    this.loadDataWrapper();
    this.initialTableLoaded++;
  }
  getTableExistingFilters(tableEvent: ITableLoadEvent) {
    const ret = {};
    Object.entries(tableEvent.tableOriginalFilters).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        const values = value.filter((filter) => !isNullObj(filter.value));
        if (values.length > 0) {
          ret[key] = values;
        }
      }
    });
    return ret;
  }
  loadExportData(config: IExportData) {
    this.subs.sink = this.reqService
      .search<T>(
        { ...this.tableEvent.pageInfo.pagination, page: config.page, size: config.size },
        { filters: this.tableEvent.filters.concat(this.additionalStaticFilters) }
      )
      .subscribe({
        next: (res: any) => {
          this.exportDataItems = res.content;
          this.exportService.parsedExportData(
            config.type,
            config.cols,
            this.exportDataItems,
            humanizeCasedString(this.moduleKeyword)
          );
        },
        error: (error) => {},
        complete: () => {},
      });
  }
  loadExportPackage(config: IExportData) {
    // const globalExportPackageService = AppInjector.get(ExportGlobalPackageDataService);
    const extraFilters: any[] = [
      ...this.additionalStaticFilters,
      ...(config?.selectedItems?.length > 0
        ? [{ property: 'code', operation: 'IN', value: config?.selectedItems?.map((item) => item?.code) }]
        : []),
    ];
    const request =
      config?.selectedItems?.length > 0 && false
        ? this.reqService.exportGlobalPackage(
            getDataKeyValueFormat({
              codesToExport: config?.selectedItems?.map((item) => item?.code),
              typesToInclude: config.typesToInclude || this.exportPackageTypesToInclude,
              name: config.name,
              description: config.description,
              exportReferenceRelations: config.exportReferenceRelations,
              exportManualRelations: config.exportManualRelations,
            })
          )
        : this.reqService.exportPackage(
            { ...this.tableEvent.pageInfo.pagination, page: config.page, size: config.size },
            {
              filters: this.tableEvent.filters.concat(extraFilters),
              typesToInclude: config.typesToInclude || this.exportPackageTypesToInclude,
              name: config.name,
              description: config.description,
              exportReferenceRelations: config.exportReferenceRelations,
              exportManualRelations: config.exportManualRelations,
            }
          );
    this.subs.sink = request.subscribe({
      next: (res: any) => {
        const toastService = AppInjector.get(ToastService);
        toastService.success('Exported Package', 'Please Check Packages');
        // this.exportDataItems = res.content;
        // this.exportService.parsedExportData(config.type, config.cols, this.exportDataItems,toSnakeCase(this.moduleKeyword));
      },
      error: (error) => {},
      complete: () => {},
    });
  }
  onViewModeChange(viewMode: IViewMode): void {}
  onOpenEditDialog(selectedRows) {
    this.appDialogService.showDialog(
      BaseFormPopupComponent,
      'Edit Rows',
      (res) => {
        if (res) this.loadDataWrapper();
      },
      {
        data: {
          dynamicViewComponent: this.editDialogComponent,
          dataService: this.reqService,
          filters: this.tableEvent.filters.concat(this.additionalStaticFilters),
          selectedRows: selectedRows,
        },
      }
    );
  }
  deleteItem(row: T): any {
    this.appDialogService.confirm({
      accept: () => {
        this.subs.sink = this.reqService.delete<T>((row as any).code || (row as any).id).subscribe({
          next: (res) => {
            this.loadDataWrapper();
          },
        });
      },
    });
  }
  hardDeleteItem(row: T): any {
    this.appDialogService.confirm({
      accept: () => {
        this.subs.sink = this.reqService.hardDelete<T>((row as any).code || (row as any).id).subscribe({
          next: (res) => {
            this.loadDataWrapper();
          },
        });
      },
    });
  }
  restoreDeletedItem(row: T): any {
    this.appDialogService.confirm({
      accept: () => {
        this.subs.sink = this.reqService.restoreDeletedItem<T>((row as any).code || (row as any).id).subscribe({
          next: (res) => {
            this.loadDataWrapper();
          },
        });
      },
    });
  }
  lockItem(row: { id: any; code: any }) {
    this.subs.sink = this.reqService
      .changeBaseStatus(row?.code || row?.id, AcknowledgmentDto.RecordStatusEnum.Locked)
      .subscribe((res) => {
        this.loadDataWrapper();
      });
  }
  archiveItem(row: { id: any; code: any }) {
    this.subs.sink = this.reqService
      .changeBaseStatus(row?.code || row?.id, AcknowledgmentDto.RecordStatusEnum.InActive)
      .subscribe((res) => {
        this.loadDataWrapper();
      });
  }
  restoreItem(row: { id: any; code: any }) {
    this.subs.sink = this.reqService
      .changeBaseStatus(row?.code || row?.id, AcknowledgmentDto.RecordStatusEnum.Active)
      .subscribe((res) => {
        this.loadDataWrapper();
      });
  }
  get settingsUrl() {
    return `/${this.routePrefix}${toKebabCase(this.moduleKeyword)}/settings`;
  }
  get deletedPageUrl() {
    return `/${this.routePrefix}${toKebabCase(this.moduleKeyword)}/deleted-items`;
  }
  get bulkOperationUrl() {
    return `/${this.routePrefix}${toKebabCase(this.moduleKeyword)}/bulk-operation`;
  }
  afterViewInit() {}
  onFixRelations(items) {
    this.reqService
      .fixRelations({
        filters: isNullObj(items)
          ? []
          : [
              {
                property: 'code',
                operation: 'IN',
                value: items?.map((x) => x?.code),
                typeShape: 'NORMAL',
              },
            ],
      })
      .subscribe({
        next: (res) => {
          const toastService = AppInjector.get(ToastService);
          toastService.success('Success', 'Relations Calculated');
        },
        error: (err) => {
          const toastService = AppInjector.get(ToastService);
          toastService.success('Error', 'Task failed try again!');
        },
      });
  }
  onSwapPageMode(event) {
    if (this.pageMode == 'NORMAL') {
      this.pageMode = 'DELETED';
    } else {
      this.pageMode = 'NORMAL';
    }
    this.loadDataWrapper();
  }
}
