import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem, DataTypeEnum } from '@shared/classes';

@Component({
  selector: 'app-progressbar-view',
  templateUrl: './progressbar-view.component.html',
  styleUrls: ['./progressbar-view.component.scss'],
})
export class ProgressbarViewComponent extends BaseViewItem<number> implements OnInit {
  @Input() viewType: DataTypeEnum = DataTypeEnum.ProgressBar;
  @Input() bgColor: string;
  @Input() styleBasedColor: string;
  @Input() tooltipHint: string;
  @Input() striped: boolean = false;
  @Input() animated: boolean = false;
  @Input() tooltipPosition: 'top' | 'bottom' | 'left' | 'right' = 'top';
  @Input() containerStyle: string;
  @Input() onBarValue: string;

  constructor() {
    super();
  }

  ngOnInit(): void { }

  getReversedColor(color) {
    if (!color) return '#000000';
    // Remove the hash if it exists
    color = color.replace('#', '');

    // Parse the color string
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);

    // Reverse each component
    const reversedR = (255 - r).toString(16).padStart(2, '0');
    const reversedG = (255 - g).toString(16).padStart(2, '0');
    const reversedB = (255 - b).toString(16).padStart(2, '0');

    // Return the reversed color as a hex string
    return `#${reversedR}${reversedG}${reversedB}`;
  }
}
