import { Injectable } from '@angular/core';
import { LocaleService } from '@core/services/locale/locale.service';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private direction: 'ltr' | 'rtl' = 'ltr'; //@NOTE: subscribe to direction service if we get one in future
  private notiType: 'primeng' = 'primeng';

  constructor(
    private messageService: MessageService,
    private localeService: LocaleService
  ) {}

  showPrimengToast(
    title: string,
    message: string,
    severity: primengToastSeverity,
    placement: primengToastPlacement = null
  ) {
    //@TODO: add onClose handler on the <p-toast> component (specifically make it onClick handler)
    let position = placement
      ? placement
      : this.direction == 'ltr'
        ? primengToastPlacement['top-right']
        : primengToastPlacement['top-left'];
    this.messageService.add({
      severity: severity,
      summary: title ? this.localeService.translate('general.messages.' + title) : null,
      detail: message ? this.localeService.translate('general.messages.' + message) : null,
      key: position,
    });
  }
  success(title: string, message: string = '', payload?: Function, placement: primengToastPlacement = null) {
    this.showPrimengToast(title, message, primengToastSeverity.success, placement);
  }
  warn(title: string, message: string = '', payload?: Function, placement: primengToastPlacement = null) {
    this.showPrimengToast(title, message, primengToastSeverity.warn), placement;
  }
  info(
    title: string,
    message: string = '',
    payload?: (notification) => void,
    notification?,
    placement: primengToastPlacement = null
  ) {
    this.showPrimengToast(title, message, primengToastSeverity.info, placement);
  }
  error(title: string, message: string = '', payload?: Function, placement: primengToastPlacement = null) {
    this.showPrimengToast(title, message, primengToastSeverity.error, placement);
  }
  show(title: string, message: string = '', payload?: Function, placement: primengToastPlacement = null) {
    this.showPrimengToast(title, message, primengToastSeverity.custom, placement);
  }
}
export enum primengToastPlacement {
  'top-right' = 'tr',
  'top-left' = 'tl',
  'bottom-right' = 'br',
  'bottom-left' = 'bl',
  'top-center' = 'tc',
  'bottom-center' = 'bc',
  'center' = 'c',
}
export enum primengToastSeverity {
  info = 'info',
  warn = 'warn',
  error = 'error',
  success = 'success',
  custom = 'custom',
}
