import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  transform(value: string, filter: string): string {
    if (!filter || !value) {
      return value;
    }

    // Function to escape special characters in the filter string
    const escapeRegExp = (str: string) => {
      return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    };

    // Escape special characters in the filter string
    const sanitizedFilter = escapeRegExp(filter);

    const regex = new RegExp(sanitizedFilter, 'gi');
    const highlightedValue = value?.replace(regex, (match) => `<span class="highlight">${match}</span>`);
    return highlightedValue;
  }
}
