import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { GeographyFieldDto } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';

@Injectable({
  providedIn: 'root',
})
export class GeographiesDataService extends BaseRequestControllerService<GeographyFieldDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.entity, '/geographies', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'translations',
      'originalLanguage',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'name',
      'owner',
      'type',
      'entityTypeValue',
      'parent',
      'category',
      'dynamics',
      'treeViewMode',
      'organization',
      'responsibility',
      'description',
    ]);
  }
  navigateToListPage() {
    this.router.navigateByUrl(`entity/geography/list`);
  }
}
