<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value"
  [dataType]="dataType"
  [label]="label"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <div class="field-checkbox flex flex-row md:flex-nowrap flex-wrap gap-1 w-full algin-items-start h-full">
    @if (!floatLabel) {
      @if (label && !instanceInFormRepeater) {
        <label
          [for]="inputId || name"
          class="m-0 md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
          [style]="
            labelWidthFit
              ? 'max-width: fit-content !important; width: fit-content !important; min-width: fit-content;'
              : ''
          "
          >{{ label }}
          @if (isControlRequired || isControlRequiredTrue) {
            <span class="text-red-500"> *</span>
          }
          @if (resetFieldCheckVisable) {
            <br />
            <app-reset-switch-input
              [checked]="!resetable"
              (onChange)="setFieldResetable($event)"
            ></app-reset-switch-input>
          }
        </label>
      }
    }
    @if (messageWrapper) {
      <p-messages severity="error" class="w-full">
        <ng-template pTemplate>
          <span (click)="toggleCheckbox()" class="input-field-label">{{ messageLabel }}</span>
          <p-checkbox
            (onChange)="onCheckChange($event)"
            [disabled]="disabled"
            [value]="value"
            [name]="name"
            [formControl]="fControl"
            [inputId]="inputId || name"
            [binary]="binary"
            class="mx-2"
          ></p-checkbox>
          @if (isControlRequiredTrue) {
            <span class="text-red-500">
              {{ 'general.messages.' + 'Must Be Checked' | translate: 'Must Be Checked' }}</span
            >
          }
        </ng-template>
      </p-messages>
    } @else {
      <p-checkbox
        (onChange)="onCheckChange($event)"
        [disabled]="disabled"
        [value]="value"
        [name]="name"
        [formControl]="fControl"
        [inputId]="inputId || name"
        [binary]="binary"
        class="align-self-start"
      ></p-checkbox>
      @if (isControlRequiredTrue) {
        <span class="text-red-500"> Must Be Checked</span>
      }
    }
    @if (floatLabel) {
      @if (label && !instanceInFormRepeater) {
        <label
          [for]="inputId || name"
          class="m-0 md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
          [style]="
            labelWidthFit
              ? 'max-width: fit-content !important; width: fit-content !important; min-width: fit-content;'
              : ''
          "
          >{{ label }}
          @if (isControlRequiredTrue) {
            <span class="text-red-500"> Must Be Checked</span>
          }
          @if (isControlRequired) {
            <span class="text-red-500"> *</span>
          }
        </label>
      }
    }
  </div>
</app-input-view-switch>
