import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { OrganizationFieldDto, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
@Injectable({
  providedIn: 'root',
})
export class OrganizationsDataService extends BaseRequestControllerService<OrganizationFieldDto> {
  private readonly makeAsDefaultUrl: string = '/make-as-default';
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.entity, '/organizations', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'translations',
      'originalLanguage',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'name',
      'owner',
      'type',
      'entityTypeValue',
      'parent',
      'category',
      'dynamics',
      'treeViewMode',
      'description',
    ]);
  }
  navigateToListPage() {
    this.router.navigateByUrl(`entity/organization/list`);
  }
  makeAsDefault(code: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData(`${this.url}/${code}${this.makeAsDefaultUrl}`, null, options);
  }
}
