import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ApiResponseAuditRequestEvent, AuditRequestDto } from '@shared/classes';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';
import { DataService } from '@shared/services/api/data.service';
@Injectable({
  providedIn: 'root',
})
export class AuditRequestDataService extends BaseRequestControllerWithRuleService<
  AuditRequestDto,
  ApiResponseAuditRequestEvent.DataEnum
> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.audit, '/audit-requests', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'description',
      'name',
      'label',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'translations',
      'originalLanguage',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'state',
      'status',
      'subject',
      'dueDate',
      'dueDateToResponse',
      'responseBy',
      'requestedBy',
      'attachments',
      'assignedTo',
      'dueDaysToResponse',
      'auditRequestStatus',
      'auditCaseResult',
    ]);
  }

  navigateToListPage() {
    this.router.navigateByUrl(`audit/audit-request/list`);
  }
}
