/**
 * Sightflare Service
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SelectFilter { 
    field?: string;
    operator?: SelectFilter.OperatorEnum;
    value?: any;
    secondValue?: any;
    and?: boolean;
    left?: SelectFilter;
    right?: SelectFilter;
    leaf?: boolean;
    empty?: boolean;
    reversed?: boolean;
    typeShape?: SelectFilter.TypeShapeEnum;
    dateTimePart?: SelectFilter.DateTimePartEnum;
}
export namespace SelectFilter {
    export type OperatorEnum = 'EQUAL' | 'NOT_EQUAL' | 'GREATER_THAN' | 'GREATER_THAN_OR_EQUAL_TO' | 'LESS_THAN' | 'LESS_THAN_OR_EQUAL_TO' | 'IN' | 'NOT_IN' | 'BETWEEN' | 'CONTAINS' | 'NOT_CONTAINS' | 'IS_NULL' | 'IS_NOT_NULL' | 'START_WITH' | 'END_WITH' | 'IS_EMPTY' | 'IS_NOT_EMPTY' | 'JOIN' | 'IS' | 'CONTAINS_ANY' | 'CONTAINS_ALL';
    export const OperatorEnum = {
        Equal: 'EQUAL' as OperatorEnum,
        NotEqual: 'NOT_EQUAL' as OperatorEnum,
        GreaterThan: 'GREATER_THAN' as OperatorEnum,
        GreaterThanOrEqualTo: 'GREATER_THAN_OR_EQUAL_TO' as OperatorEnum,
        LessThan: 'LESS_THAN' as OperatorEnum,
        LessThanOrEqualTo: 'LESS_THAN_OR_EQUAL_TO' as OperatorEnum,
        In: 'IN' as OperatorEnum,
        NotIn: 'NOT_IN' as OperatorEnum,
        Between: 'BETWEEN' as OperatorEnum,
        Contains: 'CONTAINS' as OperatorEnum,
        NotContains: 'NOT_CONTAINS' as OperatorEnum,
        IsNull: 'IS_NULL' as OperatorEnum,
        IsNotNull: 'IS_NOT_NULL' as OperatorEnum,
        StartWith: 'START_WITH' as OperatorEnum,
        EndWith: 'END_WITH' as OperatorEnum,
        IsEmpty: 'IS_EMPTY' as OperatorEnum,
        IsNotEmpty: 'IS_NOT_EMPTY' as OperatorEnum,
        Join: 'JOIN' as OperatorEnum,
        Is: 'IS' as OperatorEnum,
        ContainsAny: 'CONTAINS_ANY' as OperatorEnum,
        ContainsAll: 'CONTAINS_ALL' as OperatorEnum
    };
    export type TypeShapeEnum = 'DATETIME' | 'DATETIME_PART' | 'TEXT' | 'NORMAL';
    export const TypeShapeEnum = {
        Datetime: 'DATETIME' as TypeShapeEnum,
        DatetimePart: 'DATETIME_PART' as TypeShapeEnum,
        Text: 'TEXT' as TypeShapeEnum,
        Normal: 'NORMAL' as TypeShapeEnum
    };
    export type DateTimePartEnum = 'DAY_OF_MONTH' | 'DAY_OF_WEEK' | 'DAY_OF_YEAR' | 'HOUR' | 'MINUTE' | 'MONTH' | 'SECOND' | 'WEEK' | 'YEAR' | 'ISO_DAY_OF_WEEK' | 'ISO_WEEK' | 'ISO_WEEK_YEAR' | 'QUARTER';
    export const DateTimePartEnum = {
        DayOfMonth: 'DAY_OF_MONTH' as DateTimePartEnum,
        DayOfWeek: 'DAY_OF_WEEK' as DateTimePartEnum,
        DayOfYear: 'DAY_OF_YEAR' as DateTimePartEnum,
        Hour: 'HOUR' as DateTimePartEnum,
        Minute: 'MINUTE' as DateTimePartEnum,
        Month: 'MONTH' as DateTimePartEnum,
        Second: 'SECOND' as DateTimePartEnum,
        Week: 'WEEK' as DateTimePartEnum,
        Year: 'YEAR' as DateTimePartEnum,
        IsoDayOfWeek: 'ISO_DAY_OF_WEEK' as DateTimePartEnum,
        IsoWeek: 'ISO_WEEK' as DateTimePartEnum,
        IsoWeekYear: 'ISO_WEEK_YEAR' as DateTimePartEnum,
        Quarter: 'QUARTER' as DateTimePartEnum
    };
}


