/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TimeLog } from './timeLog';
import { StateMachineLog } from './stateMachineLog';
import { AccessRecord } from './accessRecord';
import { AuditObjectivesDetails } from './auditObjectivesDetails';
import { CurrencyValue } from './currencyValue';
import { AuditObjective } from './auditObjective';
import { UserOperationPrinciple } from './userOperationPrinciple';
import { AuditProjectControl } from './auditProjectControl';


export interface AuditProject { 
    creatorName?: string;
    creationDate?: string;
    lastModifierName?: string;
    lastModificationDate?: string;
    id?: string;
    searchScore?: number;
    recordStatus?: AuditProject.RecordStatusEnum;
    uuid?: string;
    description?: string;
    code: string;
    label?: string;
    tags?: Array<string>;
    tagsCodes?: Array<string>;
    parent?: string;
    parents?: Array<string>;
    module?: AuditProject.ModuleEnum;
    systemGenerated?: boolean;
    originalLanguage?: string;
    translations?: { [key: string]: any; };
    systemLocked?: boolean;
    softDeleteDate?: string;
    events?: Array<StateMachineLog>;
    name?: string;
    controlClassification?: AuditProject.ControlClassificationEnum;
    auditProjectStatus?: AuditProject.AuditProjectStatusEnum;
    auditUniverse?: string;
    auditProgram?: string;
    controls?: Array<string>;
    approver?: UserOperationPrinciple;
    projectScope?: AuditProject.ProjectScopeEnum;
    targetScope?: Array<string>;
    reviewers?: Array<UserOperationPrinciple>;
    owner?: UserOperationPrinciple;
    type?: string;
    fieldworkCompleted?: number;
    manager?: UserOperationPrinciple;
    objectives?: Array<string>;
    plannedStartDate?: string;
    plannedEndDate?: string;
    actualStartDate?: string;
    actualEndDate?: string;
    plannedResources?: TimeLog;
    actualResources?: TimeLog;
    actualExpenses?: CurrencyValue;
    plannedExpenses?: CurrencyValue;
    plannedDuration?: number;
    actualDuration?: number;
    actualTimeResources?: number;
    manualControls?: Array<string>;
    state?: AuditProject.StateEnum;
    codePrefix?: string;
    objectivesDto?: Array<AuditObjective>;
    programDetails?: AuditObjectivesDetails;
    manualControlsPopulated?: Array<AuditProjectControl>;
    controlsPopulated?: Array<AuditProjectControl>;
    lockedUntil?: string;
    lockedForEdit?: boolean;
    lockedBy?: string;
    userAccessLevel?: AccessRecord;
}
export namespace AuditProject {
    export type RecordStatusEnum = 'DELETED' | 'LOCKED' | 'ACTIVE' | 'IN_ACTIVE';
    export const RecordStatusEnum = {
        Deleted: 'DELETED' as RecordStatusEnum,
        Locked: 'LOCKED' as RecordStatusEnum,
        Active: 'ACTIVE' as RecordStatusEnum,
        InActive: 'IN_ACTIVE' as RecordStatusEnum
    };
    export type ModuleEnum = 'ALL' | 'DOCUMENT_APP' | 'ACKNOWLEDGMENT_APP' | 'ADMIN_APP' | 'CONFIG_APP' | 'DATA_STRUCTURE_APP' | 'BRANDING_APP' | 'ENTITY_APP' | 'FRAMEWORK_APP' | 'ISSUES_APP' | 'QUESTION_APP' | 'RELATION_APP' | 'RISK_APP' | 'USER_APP' | 'USER_OPERATIONS_APP' | 'AUDIT_APP' | 'HUB_APP' | 'TRANSPARENCY_APP' | 'PERFORMANCE_APP' | 'BCM_APP';
    export const ModuleEnum = {
        All: 'ALL' as ModuleEnum,
        DocumentApp: 'DOCUMENT_APP' as ModuleEnum,
        AcknowledgmentApp: 'ACKNOWLEDGMENT_APP' as ModuleEnum,
        AdminApp: 'ADMIN_APP' as ModuleEnum,
        ConfigApp: 'CONFIG_APP' as ModuleEnum,
        DataStructureApp: 'DATA_STRUCTURE_APP' as ModuleEnum,
        BrandingApp: 'BRANDING_APP' as ModuleEnum,
        EntityApp: 'ENTITY_APP' as ModuleEnum,
        FrameworkApp: 'FRAMEWORK_APP' as ModuleEnum,
        IssuesApp: 'ISSUES_APP' as ModuleEnum,
        QuestionApp: 'QUESTION_APP' as ModuleEnum,
        RelationApp: 'RELATION_APP' as ModuleEnum,
        RiskApp: 'RISK_APP' as ModuleEnum,
        UserApp: 'USER_APP' as ModuleEnum,
        UserOperationsApp: 'USER_OPERATIONS_APP' as ModuleEnum,
        AuditApp: 'AUDIT_APP' as ModuleEnum,
        HubApp: 'HUB_APP' as ModuleEnum,
        TransparencyApp: 'TRANSPARENCY_APP' as ModuleEnum,
        PerformanceApp: 'PERFORMANCE_APP' as ModuleEnum,
        BcmApp: 'BCM_APP' as ModuleEnum
    };
    export type ControlClassificationEnum = 'DETECTIVE' | 'CORRECTIVE' | 'PREVENTIVE' | 'DETERRENT' | 'COMPENSATING' | 'RECOVERY' | 'DIRECTIVE';
    export const ControlClassificationEnum = {
        Detective: 'DETECTIVE' as ControlClassificationEnum,
        Corrective: 'CORRECTIVE' as ControlClassificationEnum,
        Preventive: 'PREVENTIVE' as ControlClassificationEnum,
        Deterrent: 'DETERRENT' as ControlClassificationEnum,
        Compensating: 'COMPENSATING' as ControlClassificationEnum,
        Recovery: 'RECOVERY' as ControlClassificationEnum,
        Directive: 'DIRECTIVE' as ControlClassificationEnum
    };
    export type AuditProjectStatusEnum = 'DRAFT' | 'FIELD_WORK' | 'CLOSED_COMPLETED' | 'CLOSED_INCOMPLETE';
    export const AuditProjectStatusEnum = {
        Draft: 'DRAFT' as AuditProjectStatusEnum,
        FieldWork: 'FIELD_WORK' as AuditProjectStatusEnum,
        ClosedCompleted: 'CLOSED_COMPLETED' as AuditProjectStatusEnum,
        ClosedIncomplete: 'CLOSED_INCOMPLETE' as AuditProjectStatusEnum
    };
    export type ProjectScopeEnum = 'ENTITY' | 'CONTROL_OBJECTIVE' | 'RISK' | 'RISK_PROJECT' | 'AUTHORITY_DOCUMENT' | 'CITATION' | 'POLICY' | 'POLICY_SECTION' | 'MANUAL_SELECTION';
    export const ProjectScopeEnum = {
        Entity: 'ENTITY' as ProjectScopeEnum,
        ControlObjective: 'CONTROL_OBJECTIVE' as ProjectScopeEnum,
        Risk: 'RISK' as ProjectScopeEnum,
        RiskProject: 'RISK_PROJECT' as ProjectScopeEnum,
        AuthorityDocument: 'AUTHORITY_DOCUMENT' as ProjectScopeEnum,
        Citation: 'CITATION' as ProjectScopeEnum,
        Policy: 'POLICY' as ProjectScopeEnum,
        PolicySection: 'POLICY_SECTION' as ProjectScopeEnum,
        ManualSelection: 'MANUAL_SELECTION' as ProjectScopeEnum
    };
    export type StateEnum = 'DRAFT' | 'FIELD_WORK' | 'CLOSED_COMPLETED' | 'CLOSED_INCOMPLETE';
    export const StateEnum = {
        Draft: 'DRAFT' as StateEnum,
        FieldWork: 'FIELD_WORK' as StateEnum,
        ClosedCompleted: 'CLOSED_COMPLETED' as StateEnum,
        ClosedIncomplete: 'CLOSED_INCOMPLETE' as StateEnum
    };
}


