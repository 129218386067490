import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { QuestionnaireTypeDto } from '@shared/classes';
import { BaseRequestControllerService, DataService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireTypeDataService extends BaseRequestControllerService<QuestionnaireTypeDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.questions, '/questionnaire-types', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'translations',
      'originalLanguage',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'name',
      'description',
    ]);
  }

  navigateToListPage() {
    this.router.navigateByUrl(`question/questionnaire-type/list`);
  }
}
