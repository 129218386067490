import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ApiResponseControlProcedureEvent, ControlProcedure } from '@shared/classes';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';
import { DataService } from '@shared/services/api/data.service';
@Injectable({
  providedIn: 'root',
})
export class ControlProcedureDataService extends BaseRequestControllerWithRuleService<
  ControlProcedure,
  ApiResponseControlProcedureEvent.DataEnum
> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.framework, '/control-procedures', [
      'id',
      'uuid',
      'code',
      'tags',
      'tagsCodes',
      'userId',
      'name',
      'description',
      'details',
      'controlGroup',
      'controls',
      'owner',
      'status',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'translations',
      'originalLanguage',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
    ]);
  }

  navigateToListPage() {
    this.router.navigateByUrl(`framework/control-procedure/list`);
  }
}
