import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ApiResponseListModuleName, BaseResponse, GraphRequest, RequestHandlerOptions } from '@shared/classes';
import { Observable } from 'rxjs';
import { BaseRequestControllerService } from '../api/base-request-controller.service';
import { DataService } from '../api/data.service';

@Injectable({
  providedIn: 'root',
})
export class HubDataService extends BaseRequestControllerService<any> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.hub, '/info', ['code', 'label']);
  }
  ProcessCodes(codes: string[], options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<BaseResponse<any>>(
      this.url + `/process-codes`,
      { codes: codes, languages: null },
      options
    ) as Observable<any>;
  }
  GetRelationsGraph(
    code: string,
    level: 'FIRST' | 'SECOND',
    mode: 'ENTITY' | 'GENERAL',
    filters: GraphRequest,
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.dataService.postData<any>(
      this.url + `/graph/${code}/${level}/${mode}`,
      filters,
      options
    ) as Observable<any>;
  }
  getLicensedModules(options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<ApiResponseListModuleName>(
      `${environment.hub}/modules/licensed-modules`,
      options
    ) as Observable<ApiResponseListModuleName>;
  }
}
