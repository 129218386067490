/**
 * Sightflare Service
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PDFRenderConfig { 
    pdfSize?: PDFRenderConfig.PdfSizeEnum;
    header?: string;
    footer?: string;
    coverPage?: string;
    includeHeader?: boolean;
    includeFooter?: boolean;
    includeCoverPage?: boolean;
    includeTableOfContents?: boolean;
    includeReportDetails?: boolean;
    exportRaw?: boolean;
}
export namespace PDFRenderConfig {
    export type PdfSizeEnum = 'A4' | 'A5';
    export const PdfSizeEnum = {
        A4: 'A4' as PdfSizeEnum,
        A5: 'A5' as PdfSizeEnum
    };
}


