import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ApiResponseTransparencyCaseEvent, TransparencyCase } from '@shared/classes';
import { BaseRequestControllerWithRuleService, DataService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class TransparencyCaseDataService extends BaseRequestControllerWithRuleService<
  TransparencyCase,
  ApiResponseTransparencyCaseEvent.DataEnum
> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.transparencyManagement, '/transparency-cases', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'description',
      'name',
      'label',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'translations',
      'originalLanguage',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'title',
      'trackingId',
      'questionnaire',
      'closureNote',
      'questionnaireValue',
      'category',
      'assignee',
      'closureStatus',
      'status',
    ]);
  }
  navigateToListPage() {
    this.router.navigateByUrl(`transparency-management/transparency-case/list`);
  }
}
