/**
 * Sightflare Service
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ChartViewConfig { 
    isDrillDown?: boolean;
    chartType?: ChartViewConfig.ChartTypeEnum;
    viewType?: ChartViewConfig.ViewTypeEnum;
    useSystemColors?: boolean;
    colorPalette?: Array<string>;
    dataPosition?: ChartViewConfig.DataPositionEnum;
    chartVariation?: ChartViewConfig.ChartVariationEnum;
    drillDownAggregationField?: string;
}
export namespace ChartViewConfig {
    export type ChartTypeEnum = 'VERTICAL_BAR' | 'HORIZONTAL_BAR' | 'PIE' | 'DOUGHNUT' | 'LINE' | 'AREA' | 'RADAR' | 'TREE_MAP' | 'SUN_BURST' | 'SCATTERED' | 'BUBBLE' | 'SEMI_CIRCLE_DOUGHNUT' | 'FUNNEL' | 'HEATMAP' | 'SANKEY' | 'ROSE' | 'TIME_SCALE' | 'BAR_RACE' | 'LINE_RACE';
    export const ChartTypeEnum = {
        VerticalBar: 'VERTICAL_BAR' as ChartTypeEnum,
        HorizontalBar: 'HORIZONTAL_BAR' as ChartTypeEnum,
        Pie: 'PIE' as ChartTypeEnum,
        Doughnut: 'DOUGHNUT' as ChartTypeEnum,
        Line: 'LINE' as ChartTypeEnum,
        Area: 'AREA' as ChartTypeEnum,
        Radar: 'RADAR' as ChartTypeEnum,
        TreeMap: 'TREE_MAP' as ChartTypeEnum,
        SunBurst: 'SUN_BURST' as ChartTypeEnum,
        Scattered: 'SCATTERED' as ChartTypeEnum,
        Bubble: 'BUBBLE' as ChartTypeEnum,
        SemiCircleDoughnut: 'SEMI_CIRCLE_DOUGHNUT' as ChartTypeEnum,
        Funnel: 'FUNNEL' as ChartTypeEnum,
        Heatmap: 'HEATMAP' as ChartTypeEnum,
        Sankey: 'SANKEY' as ChartTypeEnum,
        Rose: 'ROSE' as ChartTypeEnum,
        TimeScale: 'TIME_SCALE' as ChartTypeEnum,
        BarRace: 'BAR_RACE' as ChartTypeEnum,
        LineRace: 'LINE_RACE' as ChartTypeEnum
    };
    export type ViewTypeEnum = 'DATA' | 'CHART' | 'CHART_AND_DATA';
    export const ViewTypeEnum = {
        Data: 'DATA' as ViewTypeEnum,
        Chart: 'CHART' as ViewTypeEnum,
        ChartAndData: 'CHART_AND_DATA' as ViewTypeEnum
    };
    export type DataPositionEnum = 'UP' | 'DOWN' | 'LEFT' | 'RIGHT';
    export const DataPositionEnum = {
        Up: 'UP' as DataPositionEnum,
        Down: 'DOWN' as DataPositionEnum,
        Left: 'LEFT' as DataPositionEnum,
        Right: 'RIGHT' as DataPositionEnum
    };
    export type ChartVariationEnum = 'STANDARD' | 'LOGARITHMIC' | 'CURVED_LOGARITHMIC' | 'POLAR_LOGARITHMIC' | 'STACKED' | 'DRILL_DOWN' | 'AREA' | 'LINE' | 'CURVED' | 'GROUPS' | 'WHEEL' | 'BAR_RACE' | 'POLAR' | 'ANCESTOR_EMPHASIS' | 'ADJACENT_EMPHASIS' | 'FULL_STACKED';
    export const ChartVariationEnum = {
        Standard: 'STANDARD' as ChartVariationEnum,
        Logarithmic: 'LOGARITHMIC' as ChartVariationEnum,
        CurvedLogarithmic: 'CURVED_LOGARITHMIC' as ChartVariationEnum,
        PolarLogarithmic: 'POLAR_LOGARITHMIC' as ChartVariationEnum,
        Stacked: 'STACKED' as ChartVariationEnum,
        DrillDown: 'DRILL_DOWN' as ChartVariationEnum,
        Area: 'AREA' as ChartVariationEnum,
        Line: 'LINE' as ChartVariationEnum,
        Curved: 'CURVED' as ChartVariationEnum,
        Groups: 'GROUPS' as ChartVariationEnum,
        Wheel: 'WHEEL' as ChartVariationEnum,
        BarRace: 'BAR_RACE' as ChartVariationEnum,
        Polar: 'POLAR' as ChartVariationEnum,
        AncestorEmphasis: 'ANCESTOR_EMPHASIS' as ChartVariationEnum,
        AdjacentEmphasis: 'ADJACENT_EMPHASIS' as ChartVariationEnum,
        FullStacked: 'FULL_STACKED' as ChartVariationEnum
    };
}


