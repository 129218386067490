import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { JWTTokenService } from '@core/services/JWT-token/jwttoken.service';
import { LicenseHandlerService } from '@core/services/license-handler/license-handler.service';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { ToastService } from '@shared/services/toast.service';
import { AppInjector } from 'app/app-injector';
import { AppBreadcrumbService } from 'app/app.breadcrumb.service';
import { cloneDeep } from 'lodash-es';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject, Subject, forkJoin } from 'rxjs';
import {
  getViewMode,
  isNullObj,
  routeToLocaleCase,
  toCamelCase,
  toKebabCase,
  toRouteCase,
  translateFromLocaleStorage,
} from '../helpers';
import { AcknowledgmentDto, RiskItemDto } from '../model';
import { PermissionActions } from './AppPermissions.enum';
import { BasePage } from './BasePage';
import { ButtonColor, IAction } from './ButtonAction';
import { ModuleKeywordRootPath } from './CodeMapper';
import { ItemPageConfigParams, PageStaticConfigParams } from './PageConfigParams';
import { MessageKeys } from './message-keys.enum';
import { SharedService } from './sharedService';
import { IViewMode, TargetTypeEnum } from './view-enums';

@Injectable()
export class ItemBasePage<T> extends BasePage implements OnDestroy {
  viewModeOnly: boolean = false;
  /**
   * item id or code from route
   *
   * @type {string}
   * @memberof ItemBasePage
   */
  public itemId: string = null;
  /**
   * params for navigation
   *
   * @type {any}
   * @memberof ItemBasePage
   */
  public pageParams: any = null;

  showStateMachineAuditTrail: Subject<boolean> = new Subject();

  /**
   * Relations table required mapper
   *
   * @type {TargetTypeEnum}
   * @memberof ItemBasePage
   */
  public fromType: TargetTypeEnum;

  /**
   * Decides if route has item id in it
   *
   * @type {Boolean}
   * @memberof ItemBasePage
   */
  public editMode: boolean = false;

  /**
   * View Mode of the page
   *
   * @type {Boolean}
   * @memberof ItemBasePage
   */
  public viewMode: IViewMode = null;

  /**
   * Item Data Store
   *
   * @type {T}
   * @memberof ItemBasePage
   */
  private _data: T = null;
  public get data() {
    return this._data;
  }
  public set data(data) {
    this._data = { ...data };
    this.isLoadingItemData = false;
    this.setRecordStatusActions();
    this.onSetData();
  }

  /**
   * Record Status Actions
   *
   * @type {IAction[]}
   * @memberof ItemBasePage
   */
  public recordStatusActions: IAction[] = [];
  /**
   * Function hook to increase the lock time for edit mode
   *
   * @type {IAction}
   * @memberof ItemBasePage
   */
  public extendLockTimeAction: IAction[] = [];
  /**
   * Decides if the item is locked
   *
   * @type {Boolean}
   * @memberof ItemBasePage
   */

  hasEditChanges$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * trigger to reset page form
   *
   * @type {number}
   * @memberof ItemBasePage
   */
  public resetFormTrigger: number = null;

  /**
   * is loading the item data
   *
   * @type {boolean}
   * @memberof ItemBasePage
   */
  public isLoadingItemData: boolean = false;

  public showLocked: boolean = false;
  public pageValuesRefreshed: boolean = false;
  public showRuleHandler: boolean = true;
  public createCopyItemAction: IAction = {
    id: 99,
    label: 'Clone',
    icon: 'pi pi-copy',
    tooltipText: 'Create Clone Item',
    tooltipPosition: 'top',
    permission: `${PermissionActions.Create}${this.moduleKeyword}`,
    command: this.copyItem.bind(this),
  };
  public SetPageValues(config: ItemPageConfigParams = new ItemPageConfigParams()) {
    this.breadCrumb = config.breadCrumb;
    this.pageActions = config.pageActions ?? [];
    this.itemId = config.itemId;
    this.fromType = config.fromType;
    this.editMode = config.itemId ? true : false;
    this.viewMode = getViewMode(this.routerNav.url);
    const breadCrumbService = AppInjector.get(AppBreadcrumbService);
    const licenseHandler = AppInjector.get(LicenseHandlerService);
    if (this.itemId) {
      this.editMode = true;
      if (breadCrumbService) breadCrumbService.setItemId(this.itemId);
      // if(licenseHandler.currentLicenseData.licenseStatus != 'EXPIRED') {
      this.getItemData(this.itemId);
      // }else{
      //     if(this.viewMode != 'view'){
      //         this.goToViewPage();
      //     }
      // }
    } else {
      this.editMode = false;
      if (breadCrumbService) breadCrumbService.setItemId(null);
    }
    this.pageValuesRefreshed = false;
    setTimeout(() => {
      this.pageValuesRefreshed = true;
    }, 10);
    this.createCopyItemAction.permission = `${PermissionActions.Create}${this.moduleKeyword}`;
  }
  sharedService: SharedService;
  constructor(
    config: PageStaticConfigParams = new PageStaticConfigParams(),
    private routerNav: Router,
    private reqService: BaseRequestControllerService<T>,
    private toast: ToastService
  ) {
    super(config);
    this.sharedService = AppInjector.get(SharedService);
    this.moduleKeyword = config.moduleKeyword ?? '';
    this.routePrefix = config.routePrefix ? toRouteCase(config.routePrefix) + '/' : (config.routePrefix ?? '');
    this.routeMiddlefix = config.routeMiddlefix ? '/' + config.routeMiddlefix : (config.routeMiddlefix ?? '');
    this.recordStatusActions = [];
    this.extendLockTimeAction = [
      {
        id: 5,
        icon: 'pi pi-unlock',
        iconPos: 'left',
        buttonStyle: 'outlined',
        color: ButtonColor.Secondary,
        command: this.goToListPage.bind(this),
        permission: `${PermissionActions.Read}${this.moduleKeyword}`,
        tooltipText: 'Go Back To Table Page',
        label: 'Leave Page',
        // displayCommand: (data: AcknowledgmentDto) => {
        //     return data?.lockedForEdit // && (Math.trunc((timerDate - now) / 60) <= 1.5)
        // }
      },
      {
        id: 4,
        icon: 'pi pi-stopwatch',
        iconPos: 'left',
        color: ButtonColor.Primary,
        command: this.extendEditLockItem.bind(this),
        permission: `${PermissionActions.Update}${this.moduleKeyword}`,
        tooltipText: 'Extend Edit Lock Time',
        label: 'Extend Edit Lock Time',
        // displayCommand: (data: AcknowledgmentDto) => {
        //     return data?.lockedForEdit // && (Math.trunc((timerDate - now) / 60) <= 1.5)
        // }
      },
    ];
  }
  goToCopyPage() {
    const url = `/${this.routePrefix}${toKebabCase(this.moduleKeyword)}${this.routeMiddlefix}/create/clone/${this.itemId}`;
    this.routerNav.navigateByUrl(url);
  }
  goToEditPage() {
    this.onViewModeChange(IViewMode.edit);
  }
  goToViewPage() {
    this.onViewModeChange(IViewMode.view);
  }
  getPageViewModeLink(viewMode: IViewMode, id = null) {
    return `/${this.routePrefix}${toKebabCase(this.moduleKeyword)}${this.routeMiddlefix}${id ? `/${viewMode}/${id}` : `/create`}`;
  }
  onViewModeChange(viewMode: IViewMode) {
    this.routerNav.navigateByUrl(this.getPageViewModeLink(viewMode, this.itemId));
  }
  getItemData(id: string) {
    this.isLoadingItemData = true;
    this.onGetItemData();
    if (this.viewMode == 'edit' && !this.viewModeOnly) {
      this.subs.sink = this.reqService.getByIdOrCodeForEdit<T>(id).subscribe({
        next: (res) => {
          this.data = res.data;
          this.afterGetitemData();
        },
        error: (err) => {
          // this.showLocked = true;
          this.goToViewPage();
          this.isLoadingItemData = false;
        },
      });
    } else {
      this.subs.sink = this.reqService.getByIdOrCode<T>(id).subscribe({
        next: (res) => {
          this.data = res.data;
          this.afterGetitemData();
          this.setRecordStatusActions();
        },
      });
    }
  }
  onGetItemData() {}
  afterGetitemData() {}
  onSetData() {}
  goToListPage() {
    this.reqService.navigateToListPage(this.pageParams);
  }
  goBack() {
    if (window?.history?.length > 1) {
      window?.history?.back();
    } else {
      this.goToListPage();
    }
  }
  handleRedirectState(code: string, redirectState: 'BACK' | 'PAGE' | 'NONE') {
    switch (redirectState) {
      case 'BACK':
        this.goBack();
        break;
      case 'PAGE':
        this.goToPermittedPage(code);
        break;
      case 'NONE':
        this.resetFormTrigger = new Date().getTime();
        break;
      default:
        break;
    }
  }
  goToPermittedPage(code: string) {
    if (isNullObj(code)) return;
    this.itemId = code;
    const permService = AppInjector.get(NgxPermissionsService);
    permService.hasPermission(PermissionActions.Update + this.moduleKeyword).then((isAllowed) => {
      isAllowed ? this.goToEditPage() : this.goToViewPage();
    });
  }
  onCancel(event) {
    this.goBack();
  }
  onSubmit(data: T, redirectState: 'BACK' | 'PAGE' | 'NONE' = 'BACK') {
    this.editMode && this.viewMode != 'create'
      ? this.updateItemV3(data, redirectState)
      : this.createItem(data, redirectState);
  }

  updateItem(data: T) {
    this.subs.sink = this.reqService.update(data, (this.data as any).id).subscribe({
      next: (res) => {
        this.toast.success(MessageKeys.success, MessageKeys[`update${this.moduleKeyword}`]);
        this.goToListPage();
      },
    });
  }

  updateItemV2(data: any) {
    // this.subs.sink = this.reqService.patchUpdate({ filters: [{ property: 'id', operation: 'EQUAL', value: (this.data as any).id }], updateItems: data }).subscribe({
    //     next: (res) => {
    //         this.toast.success(MessageKeys.success, MessageKeys[`update${this.moduleKeyword}`]);
    //         this.goToListPage();
    //     }
    // });
  }
  updateItemV3(data: any, redirectState: 'BACK' | 'PAGE' | 'NONE' = 'BACK') {
    this.subs.sink = this.reqService.patchSingleUpdate(data, (this.data as any).code).subscribe({
      next: (res) => {
        this.baseOnUpdateItem(data, redirectState);
      },
    });
  }
  private baseOnUpdateItem(data: any, redirectState: 'BACK' | 'PAGE' | 'NONE' = 'BACK') {
    this.onUpdateItemToast();
    this.onUpdateItem();
    this.handleRedirectState(null, redirectState);
    if (redirectState === 'PAGE') {
      this.refreshRules();
      if (data?.updateItems) {
        const patchData = {};
        Object.keys(data?.updateItems).forEach((key) => {
          patchData[key] = data?.updateItems[key];
        });
        this.data = { ...this.data, ...patchData };
      }
    }
  }
  onUpdateItemToast() {
    this.toast.success(MessageKeys.success, MessageKeys[`update${this.moduleKeyword}`]);
  }
  onUpdateItem() {}
  //@TODO fix data parameter type
  createItem(data: any, redirectState: 'BACK' | 'PAGE' | 'NONE' = 'BACK') {
    if (this.sharedService?.data?.tagsCodes) {
      data?.createItems?.push({ key: 'tagsCodes', value: this.sharedService.data.tagsCodes });
      this.sharedService.clear();
    }
    this.subs.sink = this.reqService.create(data).subscribe({
      next: (res) => {
        this.baseOnCreateItem(res, redirectState);
      },
    });
  }
  private baseOnCreateItem(res: any, redirectState: 'BACK' | 'PAGE' | 'NONE' = 'BACK') {
    this.onCreateItemToast();
    this.onCreateItem(res);
    this.handleRedirectState(res?.data?.code, redirectState);
  }
  onCreateItemToast() {
    this.toast.success(MessageKeys.success, MessageKeys[`create${this.moduleKeyword}`]);
  }
  onCreateItem(response) {}

  copyItem() {
    this.goToCopyPage();
    // const body = pick(data,fields);
    // this.createItem(getDataKeyValueFormat(body) as any,'PAGE');
  }
  lockItem(row: { id: any; code: any }) {
    this.subs.sink = this.reqService
      .changeBaseStatus(row.code || this.itemId, AcknowledgmentDto.RecordStatusEnum.Locked)
      .subscribe((res) => {
        // this.getItemData(row.code || this.itemId );
        this.data = { ...this.data, recordStatus: AcknowledgmentDto.RecordStatusEnum.Locked };
        this.goToViewPage();
      });
  }
  archiveItem(row: { id: any; code: any }) {
    this.subs.sink = this.reqService
      .changeBaseStatus(row.code || this.itemId, AcknowledgmentDto.RecordStatusEnum.InActive)
      .subscribe((res) => {
        // this.getItemData(row.code || this.itemId);
        this.data = { ...this.data, recordStatus: AcknowledgmentDto.RecordStatusEnum.InActive };
        this.goToViewPage();
      });
  }
  restoreItem(row: { id: any; code: any }) {
    this.subs.sink = this.reqService
      .changeBaseStatus(row.code || this.itemId, AcknowledgmentDto.RecordStatusEnum.Active)
      .subscribe((res) => {
        this.data = { ...this.data, status: AcknowledgmentDto.RecordStatusEnum.Active };
        this.getItemData(row.code || this.itemId);
      });
  }
  extendEditLockItem(data: { row: { id: any; code: any }; extendTimeDialog?: any }) {
    this.subs.sink = this.reqService.extendLock(data?.row?.code || data?.row?.id).subscribe((res) => {
      // this.getItemData(data?.row.code);
      const currentTime = new Date();
      const futureTime = new Date(currentTime.getTime() + 15 * 60000); // Adding 15 minutes in milliseconds
      (this.data as RiskItemDto).lockedUntil = futureTime.toISOString();
      data?.extendTimeDialog?.close({ preventDefault: () => {} });
    });
  }
  releaseLockItem() {
    this.subs.sink = this.reqService.releaseLock(this.itemId).subscribe((res) => {});
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
    // @TODO: add block leave page warning
    if (this.viewMode == 'edit' && this.itemId) {
      this.releaseLockItem();
    }
  }
  setRecordStatusActions() {
    this.recordStatusActions = [];
    if (!(this.data as any)?.recordStatus) return;
    const permService = AppInjector.get(NgxPermissionsService);
    const jwtTokenService = AppInjector.get(JWTTokenService);
    forkJoin({
      hasUpdatePermission: permService.hasPermission(`${PermissionActions.Update}${this.moduleKeyword}`),
      hasDeletePermission: permService.hasPermission(`${PermissionActions.Delete}${this.moduleKeyword}`),
    }).subscribe((res) => {
      const data: AcknowledgmentDto = this.data;
      if (((this.data as any)?.recordStatus as AcknowledgmentDto.RecordStatusEnum) == 'ACTIVE') {
        this.recordStatusActions = [
          ...(res.hasDeletePermission &&
          (jwtTokenService.getSfAdmin() ||
            !data?.userAccessLevel ||
            data?.userAccessLevel?.accessLevel == 'OWNER' ||
            data?.userAccessLevel?.accessLevel == 'ADMIN')
            ? [
                {
                  id: 9997,
                  icon: 'pi pi-lock',
                  buttonStyle: 'text',
                  color: ButtonColor.Warning,
                  command: this.lockItem.bind(this),
                  permission: `${PermissionActions.Update}${this.moduleKeyword}`,
                  // displayCommand: (data: AcknowledgmentDto) => data?.recordStatus && data?.recordStatus == 'ACTIVE',
                  group: { id: 1, type: 'split' },
                  label: translateFromLocaleStorage('general.actions.lock'),
                } as IAction,
              ]
            : []),
          ...(res.hasDeletePermission &&
          (jwtTokenService.getSfAdmin() ||
            !data?.userAccessLevel ||
            data?.userAccessLevel?.accessLevel == 'OWNER' ||
            data?.userAccessLevel?.accessLevel == 'ADMIN')
            ? [
                {
                  id: 9996,
                  icon: 'pi pi-inbox',
                  buttonStyle: 'text',
                  color: ButtonColor.Info,
                  command: this.archiveItem.bind(this),
                  permission: `${PermissionActions.Delete}${this.moduleKeyword}`,
                  // displayCommand: (data: AcknowledgmentDto) => data?.recordStatus && data?.recordStatus == 'ACTIVE',
                  group: { id: 1, type: 'split' },
                  label: translateFromLocaleStorage('general.actions.deactivate'),
                } as IAction,
              ]
            : []),
        ];
      } else if (((this.data as any)?.recordStatus as AcknowledgmentDto.RecordStatusEnum) == 'LOCKED') {
        this.recordStatusActions =
          res.hasDeletePermission &&
          (jwtTokenService.getSfAdmin() ||
            !data?.userAccessLevel ||
            data?.userAccessLevel?.accessLevel == 'OWNER' ||
            data?.userAccessLevel?.accessLevel == 'ADMIN')
            ? [
                {
                  id: 9994,
                  icon: 'pi pi-unlock',
                  buttonStyle: 'text',
                  color: ButtonColor.Success,
                  command: this.restoreItem.bind(this),
                  permission: `${PermissionActions.Update}${this.moduleKeyword}`,
                  // displayCommand: (data: AcknowledgmentDto) => data?.recordStatus && data?.recordStatus != 'ACTIVE' && data?.recordStatus == 'LOCKED',
                  group: { id: 1, type: 'split' },
                  label: translateFromLocaleStorage('general.actions.unlock'),
                },
              ]
            : [];
      } else if (((this.data as any)?.recordStatus as AcknowledgmentDto.RecordStatusEnum) == 'IN_ACTIVE') {
        this.recordStatusActions =
          res.hasDeletePermission &&
          (jwtTokenService.getSfAdmin() ||
            !data?.userAccessLevel ||
            data?.userAccessLevel?.accessLevel == 'OWNER' ||
            data?.userAccessLevel?.accessLevel == 'ADMIN')
            ? [
                {
                  id: 9995,
                  icon: 'pi pi-upload',
                  buttonStyle: 'text',
                  color: ButtonColor.Success,
                  command: this.restoreItem.bind(this),
                  permission: `${PermissionActions.Update}${this.moduleKeyword}`,
                  // displayCommand: (data: AcknowledgmentDto) => data?.recordStatus && data?.recordStatus != 'ACTIVE' && data?.recordStatus == 'IN_ACTIVE',
                  group: { id: 1, type: 'split' },
                  label: translateFromLocaleStorage('general.actions.activate'),
                },
              ]
            : [];
      }
    });
  }
  showStateMachine() {
    this.showStateMachineAuditTrail.next(true);
  }

  valueEditChanged(e) {
    this.hasEditChanges$.next(e);
  }
  refreshRules() {
    this.showRuleHandler = false;
    setTimeout(() => {
      this.showRuleHandler = true;
    }, 1);
  }

  dataEdit(newData) {
    this.data = cloneDeep(newData);
  }
  public routeToLocaleCase(str: string) {
    return routeToLocaleCase(str);
  }
  public toCamelCase(str: string) {
    return toCamelCase(str);
  }
  public readonly ModuleKeywordRootPath = ModuleKeywordRootPath;
}
