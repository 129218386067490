import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ApiResponseRiskItemEvent, RiskItem } from '@shared/classes';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';
import { DataService } from '@shared/services/api/data.service';

@Injectable({
  providedIn: 'root',
})
export class RiskItemDataService extends BaseRequestControllerWithRuleService<
  RiskItem,
  ApiResponseRiskItemEvent.DataEnum
> {
  constructor(
    dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.risk, '/riskItem', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'lockedBy',
      'entityId',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'translations',
      'originalLanguage',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'name',
      'description',
      'residualRisk',
      'status',
      'strategy',
      'inheritedRisk',
      'residualLikelihood',
      'inheritedLikelihood',
      'residualImpact',
      'inheritedRiskImpact',
      'value',
      'inheritedClassification',
      'residualClassification',
      'approvedActions',
      'actionsLog',
      'owner',
      'approver',
      'methodology',
      'project',
    ]);
  }
  navigateToListPage() {
    this.router.navigateByUrl(`risk/risk-item/list`);
  }
}
