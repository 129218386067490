import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { GeneratedStatisticDto } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
@Injectable({
  providedIn: 'root',
})
export class GeneratedStatisticDataService extends BaseRequestControllerService<GeneratedStatisticDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.dataStructure, '/generated-statistics', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'translations',
      'originalLanguage',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'name',
      'description',
      'label',
      'result',
      'collector',
      'runAs',
      'filterVersion',
      'collectionDate',
    ]);
  }
  navigateToListPage() {
    this.router.navigateByUrl(`data-management/generated-statistic/list`);
  }
}
