import { LocaleService } from '@core/services/locale/locale.service';
import {
  AccessRecord,
  AcknowledgmentDto,
  AggregationBody,
  ApiResponseBoolean,
  BaseListResponse,
  BasePaginationResponse,
  BaseResponse,
  ChangeStatusBody,
  CreateBody,
  FilterItem,
  OneEntityUpdateBody,
  PaginationModel,
  RequestHandlerOptions,
  SearchBody,
  SettingDto,
  UpdateItem,
} from '@shared/classes';
import { AppInjector } from 'app/app-injector';
import { PageableOptions } from 'app/shared/classes/model/frontend/pageable-options';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { DataService } from './data.service';

export class BaseRequestControllerService<BaseType> {
  private baseUrl: string = '';
  private subUrl: string = ''; //@NOTE: please be careful with the extra slash that might exist in the baseUrl
  protected defaultProjectionFields: string[] = ['id'];
  protected getByIdUrl: string = '/';
  protected searchUrl: string = '/search';
  protected searchDeletedUrl: string = '/search-deleted';
  protected deleteUrl: string = '/';
  protected hardDeleteUrl: string = '/hard/';
  protected searchPackageExportUrl: string = '/search-packages-export';
  protected searchPackageImportUrl: string = '/search-packages-import';
  protected packageExportUrl: string = '/save-package-export';
  protected packageImportUrl: string = '/save-package-import';
  protected startPackageExportUrl: string = '/start-package-export/';
  protected startPackageImportUrl: string = '/start-package-import/';
  protected startPackageExportInstantUrl: string = '/export-package-instant-run/';
  protected startPackageImportInstantUrl: string = '/import-package-instant-run/';
  protected aggregateUrl: string = '/aggregate';
  protected bulkCreateUrl: string = '/bulk-create';
  protected bulkChangeStatusUrl: string = '/bulk-change-status';
  private pageUrl: string = '/page';
  private getByIdOrCodeUrl: string = '/getByIdOrCode/';
  private getByIdOrCodeForEditUrl: string = '/getByIdOrCodeForEdit/';
  private extendLockUrl: string = '/extend/';
  private releaseLockUrl: string = '/release/';
  private listUrl: string = '/list';
  private fixRelationsUrl: string = '/fix-relations';
  private restoreItemUrl: string = '/restore/';
  private updateUrl: string = '/';
  private patchUpdateUrl: string = '/update';
  private patchSingleUpdateUrl: string = '/one-update/';
  private removeAccessUrl: string = '/remove-access';
  private grantAccessUrl: string = '/access';
  private getAccessListUrl: string = '/access';
  private exportGlobalPackageUrl: string = '/global-export-packages';
  private downloadPackageExportUrl: string = '/download-package-export/';
  private downloadPackageImportUrl: string = '/download-package-import/';
  private downloadFileUrl: string = '/download/';
  private patchSettingsUrl: string = '/settings';
  private getSettingsUrl: string = '/settings';
  private getUnlinkedItemsUrl: string = '/un-linked-';
  private addTranslationUrl: string = '/add-translation/';
  private swapTranslationUrl: string = '/swap-translation/';
  private removeTranslationUrl: string = '/remove-translation/';
  private updateTranslationUrl: string = '/update-translation/';
  private updateTranslationsUrl: string = '/update-translations/';
  private updateTranslationsFieldsUrl: string = '/update-translations-fields/';

  constructor(
    private request: DataService,
    baseUrl: string = '',
    subUrl: string = '',
    defaultProjectionFields = ['id', 'code']
  ) {
    this.baseUrl = baseUrl || environment.framework;
    this.subUrl = subUrl || '/entity';
    this.defaultProjectionFields = defaultProjectionFields || ['id'];
  }
  get projectionFields() {
    return this.defaultProjectionFields;
  }
  get url() {
    return this.baseUrl + this.subUrl;
  }
  getById<T = BaseType>(id: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.getData<BaseResponse<T>>(this.url + this.getByIdUrl + id, options) as Observable<
      BaseResponse<T>
    >;
  }
  create<T = BaseType>(body: any, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    const localeService = AppInjector.get(LocaleService);
    return this.request.postData<BaseResponse<T>>(
      this.url,
      body?.createItems
        ? {
            ...body,
            language: localeService?.language?.langCode,
          }
        : body,
      options
    ) as Observable<BaseResponse<T>>;
  }
  bulkCreate<T = BaseType>(
    body: { bodies: CreateBody[] },
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.request.postData<BaseResponse<T>>(this.url + this.bulkCreateUrl, body, options) as Observable<
      BaseResponse<T>
    >;
  }
  bulkChangeStatus<T = BaseType>(
    body: { codes: String[]; toStatus: string; changeStatusBody?: ChangeStatusBody },
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.request.postData<BaseResponse<T>>(this.url + this.bulkChangeStatusUrl, body, options) as Observable<
      BaseResponse<T>
    >;
  }
  update<T = BaseType>(body: T, id: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.putData<BaseResponse<T>>(this.url + this.updateUrl + id, body, options) as Observable<
      BaseResponse<T>
    >;
  }
  patchUpdate<T = BaseType>(
    body: OneEntityUpdateBody & { filters?: any[] },
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.request.postData<BaseResponse<T>>(this.url + this.patchUpdateUrl, body, options) as Observable<
      BaseResponse<T>
    >;
  }
  patchSingleUpdate<T = BaseType>(
    body: Array<UpdateItem>,
    idOrCode: string,
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.request.postData<BaseResponse<T>>(
      this.url + this.patchSingleUpdateUrl + idOrCode,
      { updateItems: body },
      options
    ) as Observable<BaseResponse<T>>;
  }
  removeAccess<T = BaseType>(
    idOrCode: string,
    principle: string,
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.request.postData<BaseResponse<T>>(
      `${this.url}/${idOrCode}/${principle}${this.removeAccessUrl}`,
      null,
      options
    ) as Observable<BaseResponse<T>>;
  }
  grantAccess<T = BaseType>(
    body: Array<{ principle?: string; accessLevel?: 'READ' | 'UPDATE' | 'ADMIN' | 'OWNER' }>,
    idOrCode: string,
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.request.postData<BaseResponse<T>>(
      `${this.url}/${idOrCode}${this.grantAccessUrl}`,
      body,
      options
    ) as Observable<BaseResponse<T>>;
  }
  getAccessList<T = BaseType>(idOrCode: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.getData<BaseResponse<Array<AccessRecord>>>(
      `${this.url}/${idOrCode}${this.getAccessListUrl}`,
      options
    ) as Observable<BaseResponse<Array<AccessRecord>>>;
  }
  changeBaseStatus(
    id: string,
    status: AcknowledgmentDto.RecordStatusEnum,
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.request.postData<ApiResponseBoolean>(
      `${this.url}/changeBaseStatus/${id}/${status}`,
      null,
      options
    ) as Observable<ApiResponseBoolean>;
  }
  delete<T = BaseType>(id: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.deleteData<BaseResponse<T>>(this.url + this.deleteUrl + id, options) as Observable<
      BaseResponse<T>
    >;
  }
  hardDelete<T = BaseType>(id: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.deleteData<BaseResponse<T>>(this.url + this.hardDeleteUrl + id, options) as Observable<
      BaseResponse<T>
    >;
  }
  getPage<T = BaseType>(pageOptions: PageableOptions, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.getData<BasePaginationResponse<T>>(this.url + this.pageUrl, {
      ...options,
      params: { ...pageOptions, ...options.params },
    }) as Observable<BasePaginationResponse<T>>;
  }
  search<T = BaseType>(
    pageOptions: PageableOptions,
    searchBody: SearchBody,
    options: RequestHandlerOptions = new RequestHandlerOptions(),
    searchUrl = this.searchUrl
  ) {
    searchBody.projectionFields = !!searchBody?.projectionFields
      ? searchBody.projectionFields
      : this.defaultProjectionFields;
    return this.request.postData<BasePaginationResponse<T>>(this.url + searchUrl, searchBody, {
      ...options,
      params: { ...pageOptions, ...options.params },
    }) as Observable<PaginationModel<T>>;
  }
  searchDeleted<T = BaseType>(
    pageOptions: PageableOptions,
    searchBody: SearchBody,
    options: RequestHandlerOptions = new RequestHandlerOptions(),
    searchUrl = this.searchDeletedUrl
  ) {
    return this.search<T>(pageOptions, searchBody, options, searchUrl);
  }
  searchPackageExport<T = BaseType>(
    pageOptions: PageableOptions,
    searchBody: SearchBody,
    options: RequestHandlerOptions = new RequestHandlerOptions(),
    searchUrl = this.searchPackageExportUrl
  ) {
    searchBody.projectionFields = !!searchBody?.projectionFields
      ? searchBody.projectionFields
      : [
          'id',
          'searchScore',
          'recordStatus',
          'code',
          'label',
          'version',
          'lockedForEdit',
          'creatorName',
          'lastModifierName',
          'lockedBy',
          'userAccessLevel',
          'parent',
          'parents',
          'creationDate',
          'lastModificationDate',
          'lockedUntil',
          'name',
          'mainType',
          'packageStatus',
          'mode',
          'counts',
          'fileUrl',
          'description',
          'filters',
          'typesToInclude',
        ];
    return this.request.postData<BasePaginationResponse<T>>(this.url + searchUrl, searchBody, {
      ...options,
      params: { ...pageOptions, ...options.params },
    }) as Observable<PaginationModel<T>>;
  }
  searchPackageImport<T = BaseType>(
    pageOptions: PageableOptions,
    searchBody: SearchBody,
    options: RequestHandlerOptions = new RequestHandlerOptions(),
    searchUrl = this.searchPackageImportUrl
  ) {
    searchBody.projectionFields = !!searchBody?.projectionFields
      ? searchBody.projectionFields
      : [
          'id',
          'searchScore',
          'recordStatus',
          'code',
          'label',
          'version',
          'lockedForEdit',
          'creatorName',
          'lastModifierName',
          'lockedBy',
          'userAccessLevel',
          'parent',
          'parents',
          'creationDate',
          'lastModificationDate',
          'lockedUntil',
          'name',
          'mainType',
          'packageStatus',
          'mode',
          'counts',
          'fileUrl',
          'description',
          'filters',
          'typesToInclude',
        ];
    return this.request.postData<BasePaginationResponse<T>>(this.url + searchUrl, searchBody, {
      ...options,
      params: { ...pageOptions, ...options.params },
    }) as Observable<PaginationModel<T>>;
  }
  fixRelations<T = BaseType>(
    searchBody: { filters?: FilterItem[] },
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.request.postData<BasePaginationResponse<T>>(this.url + this.fixRelationsUrl, searchBody, {
      ...options,
      params: { ...options.params },
    }) as Observable<PaginationModel<T>>;
  }
  exportPackage<T = BaseType>(
    pageOptions: PageableOptions,
    searchBody: {
      projectionFields?: string[];
      query?: any;
      filters?: FilterItem[];
      textSearch?: any;
      typesToInclude?: any[];
      mode?: any;
      name?: string;
      description?: string;
      exportReferenceRelations?: boolean;
      exportManualRelations?: boolean;
    },
    options: RequestHandlerOptions = new RequestHandlerOptions(),
    searchUrl = this.packageExportUrl
  ) {
    searchBody.projectionFields = undefined; //!!searchBody?.projectionFields ? searchBody.projectionFields : this.defaultProjectionFields;
    searchBody.mode = 'EXPORT';
    return this.request.postData<BasePaginationResponse<T>>(this.url + searchUrl, searchBody, {
      ...options,
      params: { ...pageOptions, ...options.params },
    }) as Observable<PaginationModel<T>>;
  }
  //check usage
  exportGlobalPackage<T = BaseType>(body: any, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    const tempUrl = environment.hub + this.exportGlobalPackageUrl;
    return this.request.postData<BasePaginationResponse<T>>(tempUrl, body, {
      ...options,
      params: { ...options.params },
    }) as Observable<PaginationModel<T>>;
  }
  importPackage<T = BaseType>(body: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.postData<BaseResponse<T>>(this.url + this.packageImportUrl, body, options) as Observable<
      BaseResponse<T>
    >;
  }
  //check usage
  startPackageExport<T = BaseType>(packageCode: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.postData<BaseResponse<T>>(
      this.url + this.startPackageExportUrl + packageCode,
      null,
      options
    ) as Observable<BaseResponse<T>>;
  }
  //check usage
  startPackageImport<T = BaseType>(packageCode: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.postData<BaseResponse<T>>(
      this.url + this.startPackageImportUrl + packageCode,
      null,
      options
    ) as Observable<BaseResponse<T>>;
  }
  //check usage
  startPackageExportInstant<T = BaseType>(
    packageCode: string,
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.request.postData<BaseResponse<T>>(
      this.url + this.startPackageExportInstantUrl + packageCode,
      null,
      options
    ) as Observable<BaseResponse<T>>;
  }
  //check usage
  startPackageImportInstant<T = BaseType>(
    packageCode: string,
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.request.postData<BaseResponse<T>>(
      this.url + this.startPackageImportInstantUrl + packageCode,
      null,
      options
    ) as Observable<BaseResponse<T>>;
  }
  aggregate<T = any>(
    aggregationBody: AggregationBody,
    options: RequestHandlerOptions = new RequestHandlerOptions(),
    aggregateUrl = this.aggregateUrl
  ) {
    return this.request.postData<any>(this.url + aggregateUrl, aggregationBody, options) as Observable<any>;
  }
  //shouldn't be used
  getList<T = BaseType>(options: RequestHandlerOptions = new RequestHandlerOptions(), listUrl = this.listUrl) {
    return this.request.getData<BaseListResponse<T>>(this.url + listUrl, {
      ...options,
      params: { ...options.params },
    }) as Observable<BaseListResponse<T>>;
  }
  getByIdOrCode<T = BaseType>(idOrCode: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.getData<BaseResponse<T>>(this.url + this.getByIdOrCodeUrl + idOrCode, options) as Observable<
      BaseResponse<T>
    >;
  }
  releaseLock<T = BaseType>(idOrCode: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.getData<BaseResponse<T>>(this.url + this.releaseLockUrl + idOrCode, options) as Observable<
      BaseResponse<T>
    >;
  }
  extendLock<T = BaseType>(idOrCode: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.getData<BaseResponse<T>>(this.url + this.extendLockUrl + idOrCode, options) as Observable<
      BaseResponse<T>
    >;
  }
  getByIdOrCodeForEdit<T = BaseType>(idOrCode: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.getData<BaseResponse<T>>(
      this.url + this.getByIdOrCodeForEditUrl + idOrCode,
      options
    ) as Observable<BaseResponse<T>>;
  }
  //check usage
  downloadPackageWithProgressExport(code: string, params = null) {
    return this.request.downloadWithProgress(`${this.url}${this.downloadPackageExportUrl}${code}`, params);
  }
  //check usage
  downloadPackageWithProgressImport(code: string, params = null) {
    return this.request.downloadWithProgress(`${this.url}${this.downloadPackageImportUrl}${code}`, params);
  }
  downloadFileWithProgress(fileId: string, downloadFileUrl = this.downloadFileUrl, params = null) {
    return this.request.downloadWithProgress(`${this.url}${downloadFileUrl}${fileId}`, params);
  }
  patchSettings<T = SettingDto>(body: Array<UpdateItem>, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.putData<BaseResponse<T>>(
      this.url + this.patchSettingsUrl,
      { updateItems: body },
      options
    ) as Observable<BaseResponse<T>>;
  }
  getSettings<T = SettingDto>(options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.getData<BaseResponse<T>>(this.url + this.getSettingsUrl, options) as Observable<
      BaseResponse<T>
    >;
  }
  getUnlinkedItems(unLinkString: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.getData<BaseResponse<any[]>>(
      this.url + this.getUnlinkedItemsUrl + unLinkString,
      options
    ) as Observable<BaseResponse<any[]>>;
  }
  restoreDeletedItem<T = BaseType>(code: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.postData<BaseResponse<T>>(this.url + this.restoreItemUrl + code, null, options) as Observable<
      BaseResponse<T>
    >;
  }
  addTranslation<T = BaseType>(code: string, body: any, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.request.postData<BaseResponse<T>>(
      this.url + this.addTranslationUrl + code,
      body,
      options
    ) as Observable<BaseResponse<T>>;
  }
  updateTranslation<T = BaseType>(
    code: string,
    body: any,
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.request.postData<BaseResponse<T>>(
      this.url + this.updateTranslationUrl + code,
      body,
      options
    ) as Observable<BaseResponse<T>>;
  }
  updateTranslations<T = BaseType>(
    code: string,
    body: any,
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.request.postData<BaseResponse<T>>(
      this.url + this.updateTranslationsUrl + code,
      body,
      options
    ) as Observable<BaseResponse<T>>;
  }
  updateTranslationsFields<T = BaseType>(
    code: string,
    body: any,
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.request.postData<BaseResponse<T>>(
      this.url + this.updateTranslationsFieldsUrl + code,
      body,
      options
    ) as Observable<BaseResponse<T>>;
  }
  swapTranslation<T = BaseType>(
    code: string,
    langCode: string,
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.request.postData<BaseResponse<T>>(
      this.url + this.swapTranslationUrl + code + '/' + langCode,
      null,
      options
    ) as Observable<BaseResponse<T>>;
  }
  removeTranslation<T = BaseType>(
    code: string,
    langCode: string,
    options: RequestHandlerOptions = new RequestHandlerOptions()
  ) {
    return this.request.postData<BaseResponse<T>>(
      this.url + this.removeTranslationUrl + code + '/' + langCode,
      null,
      options
    ) as Observable<BaseResponse<T>>;
  }
  navigateToListPage(params = null) {
    console.error('Define Usage in Children');
  }
}
