import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  DataTypeEnum,
  DynamicComponentBase,
  FilterItem,
  ITablePageable,
  PermissionActions,
  TargetTypeEnum,
  isNullObj,
  isStrictlyUndefined,
} from '@shared/classes';
import { PathResolverService } from '@shared/services/path-resolver.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, forkJoin, of } from 'rxjs';

@Component({
  selector: 'app-target-code-selector',
  templateUrl: './target-code-selector.component.html',
  styleUrls: ['./target-code-selector.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: TargetCodeSelectorComponent }],
})
export class TargetCodeSelectorComponent extends DynamicComponentBase implements OnInit {
  @Output() onItemAssign: EventEmitter<any> = new EventEmitter();
  @Input() selectFirst: boolean = false;
  @Input() showClear: boolean = true;
  @Input() showStatusBadge: boolean = true;
  @Input() multi: boolean = true;
  @Input() singleTableMode: boolean = false;
  @Input() optionValue: string = 'code';
  @Input() optionLabel: string = 'label';
  @Input() dataKey: string = null;
  @Input() customProjectionFields: string[] = [
    'code',
    'recordStatus',
    'label',
    'name',
    'color',
    'icon',
    'options',
    'uuid',
  ];
  @Input() customObjectFields: string[];
  @Input() appendTo: string = null;
  @Input() badgeView: boolean = false;
  @Input() useCustomBadgeMode: boolean = false;
  @Input() customBadgeColorField: string = 'color';
  @Input() customBadgeIconField: string = 'icon';
  @Input() customSort: string[] = [];
  @Input() searchUrl: string = null;
  @Input() showAddButton: boolean = false;
  @Output() onAddButtonClick: EventEmitter<any> = new EventEmitter();
  @Input() set excludeCodes(codes: string[]) {
    this._excludeCodes = codes;
    this.getOptions();
  }
  private _excludeCodes: string[] = [];
  get excludeCodes() {
    return this._excludeCodes;
  }
  @Input() set extraFilters(filters: FilterItem[]) {
    this._extraFilters = filters;
    this.getOptions();
  }
  private _extraFilters: FilterItem[] = [];
  get extraFilters() {
    return this._extraFilters;
  }
  get tableFilters() {
    return this.fetchFilters();
  }
  typesToFilter: TargetTypeEnum[] = [];
  @Input() set targetTypes(types: TargetTypeEnum[]) {
    this._targetTypes = types;
    this.typesToFilter = types;
    this.getOptions();
  }
  private _targetTypes: TargetTypeEnum[];
  get targetTypes() {
    return this._targetTypes;
  }
  @Input() extraContentProp = null;
  @Input() listBoxMode = true;
  @Input() inlineMode = false;
  @Input() tableMode = true;
  @Input() forcedRefresh = false;

  @Output() onChangesObject: EventEmitter<any> = new EventEmitter();
  items: any[] = [];
  pageInfo: ITablePageable = new ITablePageable();
  isLoading: boolean = false;
  dataType: DataTypeEnum = DataTypeEnum.CodeLink;
  tabsDataType: DataTypeEnum = DataTypeEnum.TabsListView;

  isInit = false;
  editModalVisible: boolean = false;
  editModalControl: FormControl = new FormControl(null);
  inlineControl: FormControl = new FormControl(null);
  constructor(
    private pathResolverService: PathResolverService,
    private permissionService: NgxPermissionsService
  ) {
    super();
    this.getOptions = this.getOptions.bind(this);
  }
  ngOnInit(): void {
    setTimeout(() => {
      this.isInit = true;
      // this.getOptions();
    }, 5);
    let patchVal =
      this.optionValue != undefined
        ? this.control?.getRawValue()
        : this.multi
          ? this.control?.getRawValue()
          : this.control?.getRawValue()?.code || this.control?.getRawValue()?.[this.optionLabel];
    this.editModalControl.patchValue(patchVal);
    this.inlineControl.patchValue(patchVal);

    this.subs.sink = this.onChanges.subscribe((res) => {
      let patchVal =
        this.optionValue != undefined
          ? this.control?.getRawValue()
          : this.multi
            ? this.control?.getRawValue()?.map((x) => {
              return this.dataKey ? x[this.dataKey] : x;
            })
            : this.control?.getRawValue()?.code || this.control?.getRawValue()?.[this.optionLabel];

      this.editModalControl.patchValue(patchVal);
      this.inlineControl.patchValue(res);
    });
  }
  currentChangedObj = null;
  hasCurrentChangedObj = false;
  onChangeObject(event) {
    this.currentChangedObj = event;
    if (!this.editModalVisible) {
      this.onChangesObject.emit(event);
    }
    this.hasCurrentChangedObj = true;
  }
  setInputOptions() {
    this.targetTypes = this.inputOptions?.codeSelectorInput?.targetTypes ?? this.targetTypes;
    this.extraFilters = this.inputOptions?.codeSelectorInput?.extraFilters ?? this.extraFilters;
    this.inlineMode = this.inputOptions?.codeSelectorInput?.inlineMode ?? this.inlineMode;

    this.multi = this.inputOptions?.dropDownInput?.multi ?? this.multi;
    this.showClear = this.inputOptions?.dropDownInput?.showClear ?? this.showClear;

    this.optionLabel = this.inputOptions?.dropDownInput?.optionLabel ?? this.optionLabel;
    this.optionValue = isStrictlyUndefined(this.inputOptions?.dropDownInput?.optionValue)
      ? undefined
      : (this.inputOptions?.dropDownInput?.optionValue ?? this.optionValue);
    this.dataKey = this.inputOptions?.dropDownInput?.dataKey ?? this.dataKey;
    this.appendTo = this.inputOptions?.dropDownInput?.appendTo ?? this.appendTo;
    this.showStatusBadge = this.inputOptions?.dropDownInput?.showStatusBadge ?? this.showStatusBadge;

    this.badgeView = this.inputOptions?.dropDownInput?.badgeView ?? this.badgeView;
    this.useCustomBadgeMode = this.inputOptions?.dropDownInput?.useCustomBadgeMode ?? this.useCustomBadgeMode;
    this.customBadgeColorField = this.inputOptions?.dropDownInput?.customBadgeColorField ?? this.customBadgeColorField;
    this.customBadgeIconField = this.inputOptions?.dropDownInput?.customBadgeIconField ?? this.customBadgeIconField;
    this.customProjectionFields =
      this.inputOptions?.dropDownInput?.customProjectionFields ?? this.customProjectionFields;
  }

  fetchFilters() {
    let filters = [];
    if (this.excludeCodes?.length > 0) {
      filters.push({
        property: 'code',
        operation: this.excludeCodes?.length == 1 ? 'NOT_EQUAL' : 'NOT_IN',
        value: this.excludeCodes?.length == 1 ? this.excludeCodes[0] : this.excludeCodes,
      });
    }

    filters.push({ property: 'recordStatus', operation: 'IN', value: ['LOCKED', 'ACTIVE'] });
    filters.push(...this.extraFilters);
    return filters;
  }

  async getOptions(filters: FilterItem[] = []) {
    if (!this.isInit) return;
    if (!this.inlineMode && !this.editModalVisible) return;
    // if(isNullObj(this.typesToFilter)){
    //     this.items = [];
    // }

    filters = [...filters, ...this.fetchFilters()];
    this.pageInfo.pagination.size = this.typesToFilter?.length > 1 ? 5 : 10;
    this.isLoading = true;
    // Create a map of observables with dynamic keys
    const observableMap: { [key: string]: Observable<any> } = {};
    // Create a default observable with empty values
    const defaultObservable = of({
      totalPages: 0,
      totalElements: 0,
      size: this.pageInfo?.pagination?.size || 5,
      content: [],
      number: 0,
      first: true,
      numberOfElements: 0,
      last: true,
      empty: true,
    });
    // Assign observables to map with dynamic keys
    // Create an array of promises for each permission check

    const promises = this.targetTypes.map(async (typeItem) => {
      const isAllowed = await this.permissionService.hasPermission(
        PermissionActions.Read + (typeItem == 'PUBLISHED_POLICIES' ? 'PUBLISHED_POLICY' : typeItem)
      );
      const sort = [...this.customSort, ...this.pageInfo.pagination.sort];
      const searchServiceWithUrl = this.pathResolverService
        .getDataServiceByTargetType(typeItem)
        .search(
          { ...this.pageInfo.pagination, sort: [...sort] },
          { filters: filters, projectionFields: this.customProjectionFields },
          { showLoading: false, showMsg: false },
          this.searchUrl
        );
      const searchService = this.pathResolverService
        .getDataServiceByTargetType(typeItem)
        .search(
          { ...this.pageInfo.pagination, sort: [...sort] },
          { filters: filters, projectionFields: this.customProjectionFields },
          { showLoading: false, showMsg: false }
        );
      observableMap[`${typeItem}`] =
        isAllowed && this.typesToFilter.includes(typeItem)
          ? this.searchUrl
            ? searchServiceWithUrl
            : searchService
          : defaultObservable;
    });

    // Await all the promises to complete
    await Promise.all(promises);
    // await this.targetTypes.forEach(async (type, index) => {
    //   await this.permissionService.hasPermission(PermissionActions.Read + type).then(isAllowed=>{
    //         observableMap[`${type}`] =  isAllowed ? this.pathResolverService.getDataServiceByTargetType(type).search<any>(this.pageInfo.pagination,{filters:filters,projectionFields:this.customProjectionFields},{showLoading:false,showMsg:false}) : defaultObservable;
    //     })
    // });
    this.subs.sink = forkJoin(observableMap).subscribe({
      next: (res) => {
        const tempItems = [];
        Object.keys(res).forEach((key) => {
          const value = res[key];
          const contentItems: any[] = (
            this.extraContentProp ? value?.content?.[this.extraContentProp] : value?.content
          )?.map((x) => {
            return { ...x };
          });
          tempItems.push(...contentItems);
        });
        //@TODO: try to make this respect all the multiple item list
        // this.pageInfo.totalElements = res?.totalElements;
        const activeControl = this.editModalVisible ? this.inlineControl : this.control;
        if (activeControl?.getRawValue()) {
          if (this.optionValue != undefined) {
            if (this.multi) {
              let nonExistingItems = [];
              activeControl?.getRawValue()?.forEach((element) => {
                const val = tempItems?.find((x) => x?.[this.optionValue] == element);
                if (!val) nonExistingItems.push(element);
              });
              if (nonExistingItems.length > 0) {
                // this.pathResolverService?.getDataServiceByTargetType(this.targetType)?.search<any>({page:0,all:true},{filters:[{"property":this.optionValue,"operation":"IN","value":nonExistingItems,"typeShape":"NORMAL"}],projectionFields:this.customProjectionFields}).subscribe((res)=>{
                //     (res as any[]).forEach(element => {
                //         tempItems.push({...(pick(element,(!isNullObj(this.customProjectionFields) ? this.customProjectionFields : this.pathResolverService?.getDataServiceByTargetType(this.targetType).projectionFields)))});
                //     });
                //     this.items = [...tempItems];
                // })
                this.items = [
                  ...tempItems,
                  ...nonExistingItems.map((x) => {
                    return { [this.optionValue]: x };
                  }),
                ];
              } else {
                this.items = [...tempItems];
                if (this.selectFirst) {
                  if (this.items.length > 0) {
                    activeControl.patchValue(
                      this.optionValue != undefined ? this.items?.[0]?.[this.optionValue] : this.items?.[0]
                    );
                  } else {
                    this.onChanges.emit(null);
                  }
                }
              }
            } else {
              const val = tempItems?.find((x) => x?.[this.optionValue] == activeControl?.getRawValue());
              if (!val) {
                // this.pathResolverService?.getDataServiceByTargetType(this.targetType)?.getByIdOrCode<any>(activeControl?.getRawValue()).subscribe(res=>{
                //     tempItems.push({...(pick(res?.data,(!isNullObj(this.customProjectionFields) ? this.customProjectionFields : this.pathResolverService?.getDataServiceByTargetType(this.targetType).projectionFields)))});
                //     this.items = [...tempItems];
                // })
                this.items = [...tempItems, { [this.optionValue]: activeControl?.getRawValue() }];
              } else {
                this.items = [...tempItems];
                if (this.selectFirst) {
                  if (this.items.length > 0) {
                    activeControl.patchValue(
                      this.optionValue != undefined ? this.items?.[0]?.[this.optionValue] : this.items?.[0]
                    );
                  } else {
                    this.onChanges.emit(null);
                  }
                }
              }
            }
          } else {
            if (this.multi) {
              let nonExistingItems = [];
              activeControl?.getRawValue()?.forEach((element) => {
                const val = tempItems?.find(
                  (x) => (!isNullObj(x?.code) && !isNullObj(element?.code) && x?.code == element?.code) || x == element
                );
                // const val = tempItems?.find(x=> x?.[this.optionValue] == element);
                if (!val && element.code) nonExistingItems.push(element);
              });
              if (nonExistingItems?.length > 0) {
                // this.pathResolverService?.getDataServiceByTargetType(this.targetType)?.search<any>({page:0,all:true},{filters:[{"property":"code","operation":"IN","value":nonExistingItems.map(x=> x.code),"typeShape":"NORMAL"}],projectionFields:this.customProjectionFields}).subscribe(res=>{
                //     (res as any[]).forEach(element => {
                //         tempItems.push({...(pick(element,(!isNullObj(this.customProjectionFields) ? this.customProjectionFields : this.pathResolverService?.getDataServiceByTargetType(this.targetType).projectionFields)))});
                //     });
                //     this.items = [...tempItems];
                // })
                this.items = [...tempItems, ...nonExistingItems];
              } else {
                this.items = [...tempItems];
                if (this.selectFirst) {
                  if (this.items.length > 0) {
                    activeControl.patchValue(
                      this.optionValue != undefined ? this.items?.[0]?.[this.optionValue] : this.items?.[0]
                    );
                  } else {
                    this.onChanges.emit(null);
                  }
                }
              }
            } else {
              const val = tempItems?.find(
                (x) =>
                  (!isNullObj(x?.code) &&
                    !isNullObj(activeControl?.getRawValue()?.code) &&
                    x?.code == activeControl?.getRawValue()?.code) ||
                  x == activeControl?.getRawValue()
              );
              if (!val && !isNullObj(activeControl?.getRawValue()?.code)) {
                // this.pathResolverService?.getDataServiceByTargetType(this.targetType)?.getByIdOrCode<any>(activeControl?.getRawValue()?.code).subscribe(res=>{
                //     tempItems.push({...(pick(res?.data,(!isNullObj(this.customProjectionFields) ? this.customProjectionFields : this.pathResolverService?.getDataServiceByTargetType(this.targetType).projectionFields)))})
                //     this.items = [...tempItems];
                // })
                this.items = [...tempItems, activeControl?.getRawValue()];
              } else {
                this.items = [...tempItems];
                if (this.selectFirst) {
                  if (this.items.length > 0) {
                    activeControl.patchValue(
                      this.optionValue != undefined ? this.items?.[0]?.[this.optionValue] : this.items?.[0]
                    );
                  } else {
                    this.onChanges.emit(null);
                  }
                }
              }
            }
          }
        } else {
          this.items = [...tempItems];
          if (this.selectFirst) {
            if (this.items.length > 0) {
              activeControl.patchValue(
                this.optionValue != undefined ? this.items?.[0]?.[this.optionValue] : this.items?.[0]
              );
            } else {
              this.onChanges.emit(null);
            }
          }
        }
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }
  currentTextFilterValue = [];
  onFilter(event) {
    this.currentTextFilterValue = [
      {
        left: { property: 'label', operation: 'CONTAINS', value: event, typeShape: 'NORMAL' },
        right: { property: 'code', operation: 'CONTAINS', value: event, typeShape: 'NORMAL' },
        operand: 'OR',
      },
      // {"property":"name","operation":"CONTAINS","value":event}
    ];
    this.getOptions(this.currentTextFilterValue);
  }
  onSelectTypes(event) {
    this.typesToFilter = event;
    this.getOptions(this.currentTextFilterValue);
  }
  openEditModal() {
    if (!!this.linkedControl?.invalid || this.linkedControl?.disabled || this.fControl.disabled) return;
    this.items = [];
    this.currentTextFilterValue = [];
    let patchVal =
      this.optionValue != undefined
        ? this.control?.getRawValue()
        : this.multi
          ? this.control?.getRawValue()
          : this.control?.getRawValue()?.code || this.control?.getRawValue()?.[this.optionLabel];
    this.editModalControl.patchValue(patchVal);
    this.inlineControl.patchValue(this.control?.getRawValue());
    this.editModalVisible = true;
  }
  onFocusChange(event) {
    // this.openEditModal();
  }
  onEditModalInputKeyDown(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      // Enter or Space key was pressed
      this.openEditModal();
    }
  }
  onEditModalSave() {
    this.fControl?.patchValue(this.inlineControl?.getRawValue());
    this.editModalVisible = false;
    if (this.hasCurrentChangedObj) this.onChangeObject(this.currentChangedObj);
  }
  onInlineSave() {
    this.fControl?.patchValue(this.inlineControl?.getRawValue());
    if (this.hasCurrentChangedObj) this.onChangeObject(this.currentChangedObj);
  }
  addButtonClick(value: string) {
    this.onAddButtonClick.emit(value);
  }
}
