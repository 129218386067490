import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { AuditProgramReport } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
@Injectable({
  providedIn: 'root',
})
export class AuditProgramReportDataService extends BaseRequestControllerService<AuditProgramReport> {
  //auditProject
  //reporterNotes
  //charts
  //isFinal
  //issues
  //auditorFindings
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.audit, '/audit-program-reports', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'description',
      'name',
      'label',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'translations',
      'originalLanguage',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'auditProgram',
      'reporterNotes',
      'charts',
      'isFinal',
      'issues',
      'auditorFindings',
    ]);
  }

  navigateToListPage() {
    this.router.navigateByUrl(`audit/audit-program-report/list`);
  }
}
