import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ApiResponseControlGroupEvent, BaseResponse, ControlGroup, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ControlGroupDataService extends BaseRequestControllerWithRuleService<
  ControlGroup,
  ApiResponseControlGroupEvent.DataEnum
> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.framework, '/control-groups', [
      'id',
      'uuid',
      'code',
      'tags',
      'tagsCodes',
      'userId',
      'name',
      'description',
      'policy',
      'controlGroupStatus',
      'owner',
      'attestationRespondent',
      'controlFrequency',
      'controlAttestationTemplate',
      'controlEntities',
      'policySection',
      'progress',
      'controlClassification',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'translations',
      'originalLanguage',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
    ]);
  }

  navigateToListPage() {
    this.router.navigateByUrl(`framework/control-group/list`);
  }

  toggleState<T>(id: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<BaseResponse<T>>(this.url + '/toggle/' + id, options) as Observable<
      BaseResponse<any>
    >;
  }
}
