@switch (viewType) {
  @case (dataTypes.Text) {
    @if (!useField || useField) {
      <app-text-view
        [filter]="options?.filter"
        [clamp]="options?.clamp ?? true"
        [useStaticMaxWidth]="options?.useStaticMaxWidth !== undefined ? options?.useStaticMaxWidth : true"
        [data]="data"
      ></app-text-view>
    } @else {
      <app-basic-input
        class="view-mode"
        label=""
        [placeholder]="''"
        [control]="formControl"
        [viewMode]="'edit'"
        [readOnly]="true"
      ></app-basic-input>
    }
  }
  @case (dataTypes.LongText) {
    @if (!useField || useField) {
      <app-html-text-view [data]="data"></app-html-text-view>
    } @else {
      <app-text-editor
        class="view-mode"
        [height]="'115px'"
        [name]="'long_text'"
        label=""
        [placeholder]="''"
        [hideToolbar]="true"
        [control]="disabledFormControl"
        [viewMode]="'edit'"
      ></app-text-editor>
    }
  }
  @case (dataTypes.SunEditorView) {
    <!-- <app-sun-editor-view [data]="data"></app-sun-editor-view> -->
  }
  @case (dataTypes.LongWithHoverText) {
    <app-htmlTextShortenWithHoverViewComponent [data]="data"></app-htmlTextShortenWithHoverViewComponent>
  }
  @case (dataTypes.Chip) {
    <app-chip-view [data]="data"></app-chip-view>
  }
  @case (dataTypes.Color) {
    <app-color-view [data]="data"></app-color-view>
  }
  <!-- <ng-container *ngSwitchCase="dataTypes.Slider">
  <app-slider-view [data]="data"></app-slider-view>
</ng-container> -->
  @case (dataTypes.ProgressBar) {
    <app-progressbar-view [data]="data"></app-progressbar-view>
  }
  @case (dataTypes.Number) {
    @if (!useField || useField) {
      <app-number-view [data]="data"></app-number-view>
    } @else {
      <app-basic-input
        class="view-mode"
        label=""
        [placeholder]="''"
        [control]="formControl"
        [viewMode]="'edit'"
        [readOnly]="true"
      ></app-basic-input>
    }
  }
  @case (dataTypes.Badge) {
    <app-badge-view [translationKeyPrefix]="translationKeyPrefix" [data]="data"></app-badge-view>
  }
  @case (dataTypes.UserListView) {
    <app-user-list-view [data]="data"></app-user-list-view>
  }
  @case (dataTypes.ResponsibilityListView) {
    <app-responsibility-avatar-list [data]="data"></app-responsibility-avatar-list>
  }
  @case (dataTypes.ResponsibilityWithAllOneMode) {
    <app-responsibility-one-all-mode [data]="data"></app-responsibility-one-all-mode>
  }
  @case (dataTypes.Date) {
    @if (!useField || useField) {
      <app-date-view [data]="data" [dateFormat]="'long'"></app-date-view>
    } @else {
      <app-date-input
        class="view-mode"
        [label]=""
        [placeholder]=""
        [name]="'date'"
        [showIcon]="false"
        [control]="formControl"
        [viewMode]="'edit'"
        [readOnly]="true"
        [maxDate]="oldDate"
      ></app-date-input>
    }
  }
  @case (dataTypes.DateLong) {
    @if (!useField || useField) {
      <app-date-view [data]="data" [dateFormat]="'long'"></app-date-view>
    } @else {
      <app-date-input
        class="view-mode"
        [label]=""
        [placeholder]=""
        [name]="'date'"
        [showIcon]="false"
        [control]="formControl"
        [viewMode]="'edit'"
        [readOnly]="true"
        [maxDate]="oldDate"
      ></app-date-input>
    }
  }
  @case (dataTypes.DateShort) {
    @if (!useField || useField) {
      <app-date-view [data]="data" [dateFormat]="'short'"></app-date-view>
    } @else {
      <app-date-input
        class="view-mode"
        [label]=""
        [placeholder]=""
        [name]="'date'"
        [showIcon]="false"
        [control]="formControl"
        [viewMode]="'edit'"
        [readOnly]="true"
        [maxDate]="oldDate"
      ></app-date-input>
    }
  }
  @case (dataTypes.Time) {
    @if (!useField || useField) {
      <app-date-view [data]="data" [dateFormat]="'time'"></app-date-view>
    } @else {
      <app-date-input
        class="view-mode"
        [label]=""
        [placeholder]=""
        [name]="'date'"
        [showIcon]="false"
        [control]="formControl"
        [viewMode]="'edit'"
        [readOnly]="true"
        [maxDate]="oldDate"
      ></app-date-input>
    }
  }
  @case (dataTypes.Link) {
    <app-hyper-text-view [linkPrefix]="options?.linkPrefix" [data]="data"></app-hyper-text-view>
  }
  @case (dataTypes.CountDown) {
    <app-count-down-view [showTimeText]="options?.showTimeText" [date]="data"></app-count-down-view>
  }
  @case (dataTypes.Icon) {
    <span><i [class]="data"></i></span>
  }
  @case (dataTypes.CodeWithStatus) {
    <app-code-with-lock-status-view
      [data]="data"
      [lockedForEdit]="data?.lockedForEdit"
    ></app-code-with-lock-status-view>
  }
  @case (dataTypes.ProgressBarStacked) {
    <app-stacked-progress-bar-view [data]="data"></app-stacked-progress-bar-view>
  }
  @case (dataTypes.GenericProgressBar) {
    <app-generic-progress-bar-view [data]="data"></app-generic-progress-bar-view>
  }
  @case (dataTypes.CodeLink) {
    <app-code-navigation
      [showLabel]="options?.showLabel ?? true"
      [data]="data"
      [showTooltip]="true"
    ></app-code-navigation>
  }
  @case (dataTypes.Image) {
    <app-image-view [data]="data"></app-image-view>
  }
  @case (dataTypes.AvatarInfoList) {
    <app-avatar-info-tray-view [data]="data"></app-avatar-info-tray-view>
  }
  @case (dataTypes.Knob) {
    <app-knob-progress-view [size]="40" [data]="data"></app-knob-progress-view>
  }
  @case (dataTypes.DynamicComponent) {
    <ng-template
      appDynamicComponent
      [mode]="'view'"
      [dynamicComponentInfo]="{
        componentType: options.dynamicComponent,
        options: { data: data, label: '', name: 'dynamic', control: null },
      }"
    ></ng-template>
  }
  @case (dataTypes.RiskMeter) {
    <app-risk-meter-view [value]="options?.value" [riskMeth]="options?.riskMeth"></app-risk-meter-view>
  }
  @case (dataTypes.RiskItemCell) {
    <app-risk-item-cell [value]="data" [riskMeth]="options?.riskMeth"></app-risk-item-cell>
  }
  @case (dataTypes.RelationTypeCell) {
    <app-relation-type-cell
      [value]="data"
      [relationTypes]="options?.relationTypes"
      [fullWidth]="options?.fullWidth"
    ></app-relation-type-cell>
  }
  @case (dataTypes.RelationType) {
    <app-relation-type-cell [data]="data"></app-relation-type-cell>
  }
  @case (dataTypes.AuditTrailAcl) {
    <app-acl-view [data]="data"></app-acl-view>
  }
  @case (dataTypes.AuditTrailDynamics) {
    <app-dynamics-view [data]="data"></app-dynamics-view>
  }
  @case (dataTypes.PrincipleList) {
    <app-principle-list-view [data]="data" [showModeIcon]="options?.showModeIcon"></app-principle-list-view>
  }
  @case (dataTypes.relationNiceMode) {
    <app-relation-nicemode-view [data]="data" [relationTypes]="options?.relationTypes"></app-relation-nicemode-view>
  }
  @case (dataTypes.HumanizedText) {
    @if (!useField || useField) {
      <app-humanized-text-view [filter]="options?.filter" [data]="data"></app-humanized-text-view>
    } @else {
      <app-basic-input
        class="view-mode"
        label=""
        [placeholder]="''"
        [control]="formControl"
        [viewMode]="'edit'"
        [readOnly]="true"
      ></app-basic-input>
    }
  }
  @case (dataTypes.gvlItem) {
    <app-gvl-item-view
      [data]="data"
      [showNumeric]="options?.showNumeric"
      [showDescription]="options?.showDescription"
    ></app-gvl-item-view>
  }
  @case (dataTypes.gvlItemList) {
    <app-gvl-item-list-view
      [data]="data"
      [showNumeric]="options?.showNumeric"
      [showDescription]="options?.showDescription"
    ></app-gvl-item-list-view>
  }
  @case (dataTypes.TimeView) {
    <app-time-view
      [data]="data"
      [year]="options?.year"
      [month]="options?.month"
      [week]="options?.week"
      [day]="options?.day"
      [hour]="options?.hour"
    ></app-time-view>
  }
  @case (dataTypes.PlannedProgressBar) {
    <app-planned-progress-bar-view
      [data]="data"
      [useRawValue]="options?.useRawValue"
      [viewDataType]="options?.viewDataType"
      [viewOptions]="options?.viewOptions"
      [optionValue]="options?.optionValue"
      [progressColor]="options?.progressColor"
    ></app-planned-progress-bar-view>
  }
  @case (dataTypes.CurrencyView) {
    <app-currency-view [data]="data"></app-currency-view>
  }
  @case (dataTypes.PaletteView) {
    <app-palette-view [data]="data"></app-palette-view>
  }
  @case (dataTypes.StateMachineLogView) {
    <app-event-action-view [data]="data"></app-event-action-view>
  }
  @case (dataTypes.TagSearch) {
    <app-tag-search-view [data]="data"></app-tag-search-view>
  }
  @case (dataTypes.TabsListView) {
    <app-target-code-list-box-view class="w-full" [value]="data"></app-target-code-list-box-view>
  }
  @default {
    {{ data }}
  }
}
<!-- <ng-template #badgeInputTemplate>
<app-drop-down-input
  [label]="''"
  [placeholder]="''"
  [name]="'type'"
  [optionLabel]="'label'"
  [items]="badgeList"
  [optionValue]="'label'"
  [control]="formControl"
  [viewMode]="'edit'"
  [badgeView]="true"
  [showClear]="false"
></app-drop-down-input>
</ng-template> -->
