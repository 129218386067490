/**
 * Sightflare Service
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SelectFilter } from './selectFilter';


export interface FilterItem { 
    property?: string;
    operation?: FilterItem.OperationEnum;
    value?: any;
    secondValue?: any;
    left?: FilterItem;
    right?: FilterItem;
    operand?: FilterItem.OperandEnum;
    typeShape?: FilterItem.TypeShapeEnum;
    dateTimePart?: FilterItem.DateTimePartEnum;
    leaf?: boolean;
    selectFilter?: SelectFilter;
}
export namespace FilterItem {
    export type OperationEnum = 'EQUAL' | 'NOT_EQUAL' | 'GREATER_THAN' | 'GREATER_THAN_OR_EQUAL_TO' | 'LESS_THAN' | 'LESS_THAN_OR_EQUAL_TO' | 'IN' | 'NOT_IN' | 'BETWEEN' | 'CONTAINS' | 'NOT_CONTAINS' | 'IS_NULL' | 'IS_NOT_NULL' | 'START_WITH' | 'END_WITH' | 'IS_EMPTY' | 'IS_NOT_EMPTY' | 'JOIN' | 'IS' | 'CONTAINS_ANY' | 'CONTAINS_ALL';
    export const OperationEnum = {
        Equal: 'EQUAL' as OperationEnum,
        NotEqual: 'NOT_EQUAL' as OperationEnum,
        GreaterThan: 'GREATER_THAN' as OperationEnum,
        GreaterThanOrEqualTo: 'GREATER_THAN_OR_EQUAL_TO' as OperationEnum,
        LessThan: 'LESS_THAN' as OperationEnum,
        LessThanOrEqualTo: 'LESS_THAN_OR_EQUAL_TO' as OperationEnum,
        In: 'IN' as OperationEnum,
        NotIn: 'NOT_IN' as OperationEnum,
        Between: 'BETWEEN' as OperationEnum,
        Contains: 'CONTAINS' as OperationEnum,
        NotContains: 'NOT_CONTAINS' as OperationEnum,
        IsNull: 'IS_NULL' as OperationEnum,
        IsNotNull: 'IS_NOT_NULL' as OperationEnum,
        StartWith: 'START_WITH' as OperationEnum,
        EndWith: 'END_WITH' as OperationEnum,
        IsEmpty: 'IS_EMPTY' as OperationEnum,
        IsNotEmpty: 'IS_NOT_EMPTY' as OperationEnum,
        Join: 'JOIN' as OperationEnum,
        Is: 'IS' as OperationEnum,
        ContainsAny: 'CONTAINS_ANY' as OperationEnum,
        ContainsAll: 'CONTAINS_ALL' as OperationEnum
    };
    export type OperandEnum = 'AND' | 'OR';
    export const OperandEnum = {
        And: 'AND' as OperandEnum,
        Or: 'OR' as OperandEnum
    };
    export type TypeShapeEnum = 'DATETIME' | 'DATETIME_PART' | 'TEXT' | 'NORMAL';
    export const TypeShapeEnum = {
        Datetime: 'DATETIME' as TypeShapeEnum,
        DatetimePart: 'DATETIME_PART' as TypeShapeEnum,
        Text: 'TEXT' as TypeShapeEnum,
        Normal: 'NORMAL' as TypeShapeEnum
    };
    export type DateTimePartEnum = 'DAY_OF_MONTH' | 'DAY_OF_WEEK' | 'DAY_OF_YEAR' | 'HOUR' | 'MINUTE' | 'MONTH' | 'SECOND' | 'WEEK' | 'YEAR' | 'ISO_DAY_OF_WEEK' | 'ISO_WEEK' | 'ISO_WEEK_YEAR' | 'QUARTER';
    export const DateTimePartEnum = {
        DayOfMonth: 'DAY_OF_MONTH' as DateTimePartEnum,
        DayOfWeek: 'DAY_OF_WEEK' as DateTimePartEnum,
        DayOfYear: 'DAY_OF_YEAR' as DateTimePartEnum,
        Hour: 'HOUR' as DateTimePartEnum,
        Minute: 'MINUTE' as DateTimePartEnum,
        Month: 'MONTH' as DateTimePartEnum,
        Second: 'SECOND' as DateTimePartEnum,
        Week: 'WEEK' as DateTimePartEnum,
        Year: 'YEAR' as DateTimePartEnum,
        IsoDayOfWeek: 'ISO_DAY_OF_WEEK' as DateTimePartEnum,
        IsoWeek: 'ISO_WEEK' as DateTimePartEnum,
        IsoWeekYear: 'ISO_WEEK_YEAR' as DateTimePartEnum,
        Quarter: 'QUARTER' as DateTimePartEnum
    };
}


