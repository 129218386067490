<app-input-view-switch
  *ngIf="false"
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="getViewData"
  [dataType]="dataType"
  [label]="label"
  [multiViewFields]="multi"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <span class="field w-full" [class.p-float-label]="floatLabel" [class.mb-1]="control?.dirty && this.control.errors">
    <!-- <p-treeSelect
            [options]="formattedEntities"
            [selectionMode]="multi ? 'multiple' : 'single'"
            [(ngModel)]="selectedNodes"
            placeholder="Select Item"
            [filter]="false"
            [filterInputAutoFocus]="false"
            (ngModelChange)="onValueChanges($event)"
            (onNodeUnselect)="onNodeUnselect($event)"
            [showClear]="showClear"
            [disabled]="control?.disabled"
            [metaKeySelection]="false"
            [class.ng-invalid]="fControl?.invalid"
            [class.ng-dirty]="fControl?.dirty"
            >
        <ng-template let-node pTemplate="root">
            <div class="">
                <span class="h-fit">
                    {{node.label}}
                </span>
            </div>
        </ng-template>
        <ng-template let-node pTemplate="default">
            <div class="w-full flex gap-2 align-items-center">
                <div *ngIf="useResponsibilityMode && principleModeSelection =='SELECTION'">
                    <p-selectButton [options]="responsibilityModeOptions" [(ngModel)]="node.data.mode"
                    optionLabel="label" optionValue="value" (onOptionClick)="onOptionClick($event,node)" (onChange)="onChangeRespMode($event,node)" styleClass="resp-mode-buttons">
                    <ng-template let-item>
                        <div class="flex flex-row gap-2 align-items-center w-full align-self-center justify-content-center"  [pTooltip]="item.tooltip"  tooltipPosition="top"
                        style="padding:0.629rem 0;"
                        >
                            <i [class]="item.icon"></i>
                            <div *ngIf="item.label">
                                {{item.label}}
                            </div>
                        </div>
                    </ng-template>
                </p-selectButton>
                </div>
                <span class="h-fit flex align-items-center">
                    <app-badge-item *ngIf="allUsersMap[node.label]?.responsibilityCode" class="mr-2" [pTooltip]="!(allUsersMap[node.label]?.members?.length > 0) ? 'No Members' : (allUsersMap[node.label]?.isMember ? 'You are a Member in this group' : '')" [styleClass]="'text-xs'" [status]="!(allUsersMap[node.label]?.members?.length > 0) ? 'red' : (allUsersMap[node.label]?.isMember ? 'gold' : 'green')" [text]="allUsersMap[node.label]?.responsibilityCode" [flicker]="!(allUsersMap[node?.label]?.members?.length > 0)"></app-badge-item>
                    <p>{{allUsersMap[node?.label]?.responsibilityCode ? allUsersMap[node?.label]?.responsibilityName :  node.label}}</p>
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-options="options">
            <div class="flex p-3 pb-0 w-full">
                <div class="p-input-icon-right flex-1" (click)="$event.stopPropagation()">
                    <ng-container *ngIf="isLoading; else elseTemplate">
                        <i class="pi pi-spin pi-spinner"></i>
                    </ng-container>
                    <ng-template #elseTemplate>
                        <i class="pi pi-search"></i>
                    </ng-template>

                    <input appMaxCharacterValidator type="text" pInputText [placeholder]="placeholder"
                    [(ngModel)]="filterValue"
                    name="dd-filter" id="dd-filter"
                    class="w-full"
                    (ngModelChange)="this.filterValueUpdate.next($event)"
                    pAutoFocus [autofocus]="true"
                    autocomplete="off"
                    >
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="filter" let-options="options">
            <div class="flex p-3 w-full">
                <div class="p-input-icon-right flex-1" (click)="$event.stopPropagation()">
                    <ng-container *ngIf="true; else elseTemplate">
                        <i class="pi pi-spin pi-spinner"></i>
                    </ng-container>
                    <ng-template #elseTemplate>
                        <i class="pi pi-search"></i>
                    </ng-template>

                    <input appMaxCharacterValidator type="text" pInputText [placeholder]="placeholder"
                    [(ngModel)]="filterValue"
                    name="dd-filter" id="dd-filter"
                    class="w-full"
                    (ngModelChange)="this.filterValueUpdate.next($event)"
                    pAutoFocus [autofocus]="true"
                    >
                </div>
            </div>
        </ng-template>
    </p-treeSelect> -->
    <app-drop-down-input
      [label]="''"
      [placeholder]="placeholder ?? 'Search Target'"
      [name]="'dropdown'"
      [optionLabel]="'name'"
      [items]="unformattedOptions"
      [optionValue]="undefined"
      [control]="fControl"
      [multi]="multi"
      [dataKey]="'name'"
      [appendTo]="'body'"
      [lazy]="true"
      [loading]="isLoading"
      [onFilterCommand]="onFilter.bind(this)"
      [floatLabel]="floatLabel"
      [customFilter]="true"
      [showClear]="showClear"
      [showStatusBadge]="showStatusBadge"
      [badgeView]="false"
      [useCustomBadgeMode]="useCustomBadgeMode"
      [customBadgeColorField]="customBadgeColorField"
      [customBadgeIconField]="customBadgeIconField"
      [listBoxMode]="true"
      [disabled]="!!linkedControl?.invalid || linkedControl?.disabled"
    >
      <ng-template let-node #preItemTemplate>
        <div *ngIf="useResponsibilityMode && principleModeSelection == 'SELECTION'">
          <p-selectButton
            [options]="responsibilityModeOptions"
            [(ngModel)]="node.mode"
            optionLabel="label"
            optionValue="value"
            (onOptionClick)="onOptionClick($event, node)"
            (onChange)="onChangeRespMode($event, node)"
            styleClass="resp-mode-buttons"
          >
            <ng-template let-item>
              <div
                class="flex flex-row gap-2 align-items-center w-full align-self-center justify-content-center"
                [pTooltip]="item.tooltip"
                tooltipPosition="top"
                style="padding: 0.629rem 0"
              >
                <i [class]="item.icon"></i>
                <div *ngIf="item.label">
                  {{ item.label }}
                </div>
              </div>
            </ng-template>
          </p-selectButton>
        </div>
      </ng-template>
      <ng-template let-node #itemTemplate>
        <app-text-view [data]="node.name"></app-text-view>
      </ng-template>
    </app-drop-down-input>
    <!-- <label
            *ngIf="floatLabel"
            [for]="name"
            >
                {{ label}}<span *ngIf="isControlRequired" class="text-red-500"> *</span>
            <span
                [class]="isLoading ? 'ml-2 pi pi-spin pi-spinner' : ''"
            ></span
        ></label> -->
  </span>
  <ng-container *ngIf="control?.dirty">
    <small class="p-error p-invalid" *ngIf="control?.hasError('required')">{{ label }} {{ 'is Required' }}</small>
    <small class="p-error p-invalid" *ngIf="customError && control?.invalid">{{ customErrorLabel }}</small>
  </ng-container>
  <small class="text-500 block" *ngIf="helpText">{{ helpText }}</small>
</app-input-view-switch>
<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="getViewData"
  [dataType]="dataType"
  [label]="label"
  [multiViewFields]="multi"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <ng-container *ngIf="inlineMode; then inlineTemplate; else editTemplate"></ng-container>
  <!-- <ng-container *ngIf="viewMode == 'create'; then (listBoxMode ? inlineTemplate : selectorTemplate); else editTemplate"></ng-container> -->
</app-input-view-switch>
<ng-template #inlineTemplate>
  <app-drop-down-input
    [label]="''"
    [placeholder]="placeholder ?? 'Search Target'"
    [name]="'dropdown'"
    [optionLabel]="'name'"
    [items]="unformattedOptions"
    [optionValue]="undefined"
    [control]="editModalVisible ? inlineControl : fControl"
    [multi]="multi"
    [dataKey]="'name'"
    [appendTo]="'body'"
    [lazy]="true"
    [loading]="isLoading"
    [onFilterCommand]="onFilter.bind(this)"
    [floatLabel]="floatLabel"
    [customFilter]="true"
    [showClear]="showClear"
    [showStatusBadge]="showStatusBadge"
    [badgeView]="false"
    [useCustomBadgeMode]="useCustomBadgeMode"
    [customBadgeColorField]="customBadgeColorField"
    [customBadgeIconField]="customBadgeIconField"
    [listBoxMode]="true"
    [disabled]="!!linkedControl?.invalid || linkedControl?.disabled"
  >
    <ng-template let-node #preItemTemplate>
      <div *ngIf="useResponsibilityMode && principleModeSelection == 'SELECTION' && node?._pickListTargetItem">
        <p-selectButton
          [options]="responsibilityModeOptions"
          [(ngModel)]="node.mode"
          optionLabel="label"
          optionValue="value"
          (onOptionClick)="onOptionClick($event, node)"
          (onChange)="onChangeRespMode($event, node)"
          styleClass="resp-mode-buttons"
        >
          <ng-template let-item>
            <div
              class="flex flex-row gap-2 align-items-center w-full align-self-center justify-content-center"
              [pTooltip]="item.tooltip"
              tooltipPosition="top"
              style="padding: 0.629rem 0"
            >
              <i [class]="item.icon"></i>
              <div *ngIf="item.label">
                {{ item.label }}
              </div>
            </div>
          </ng-template>
        </p-selectButton>
      </div>
    </ng-template>
    <ng-template #resetCheckTemplate>
      @if (resetFieldCheckVisable) {
        <app-reset-switch-input [checked]="!resetable" (onChange)="setFieldResetable($event)"></app-reset-switch-input>
      }
    </ng-template>
    <ng-template let-node #itemTemplate>
      <app-text-view [data]="node.name"></app-text-view>
    </ng-template>
  </app-drop-down-input>
</ng-template>
<ng-template #editTemplate>
  <div class="field" [class.mb-1]="editModalControl?.dirty && this.editModalControl.errors">
    <span
      class="p-inputgroup flex flex-row relative hide-selection flex flex-row md:flex-nowrap flex-wrap w-full algin-items-start"
      [class.p-float-label]="floatLabel"
    >
      <ng-container *ngIf="!floatLabel; then labelTemplate"></ng-container>
      <input
        appMaxCharacterValidator
        class="w-full border-round-left cursor-pointer"
        [type]="'text'"
        pInputText
        readonly
        [formControl]="editModalControl"
        [placeholder]="label"
        (focus)="onFocusChange($event)"
        (click)="openEditModal()"
        (keydown)="onEditModalInputKeyDown($event)"
        [style]="
          'color:transparent; ' +
          (!fControl?.disabled ? 'width:calc(100% - (41px + 12rem)) !important; ' : '') +
          ('height: max(32px,' +
            (multi
              ? 1.543 * editModalControl?.value?.length + (0.5 * (editModalControl?.value?.length - 1) + 0.858)
              : '2.4') +
            'rem)')
        "
        [class.ng-invalid]="fControl?.invalid"
        [class.ng-dirty]="fControl?.dirty"
      />
      <ng-container *ngIf="floatLabel; then labelTemplate"></ng-container>
      <ng-template #labelTemplate>
        <label
          class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full mr-1 input-field-label"
          *ngIf="label && !instanceInFormRepeater"
          >{{ label }}<span *ngIf="isControlRequired" class="text-red-500"> *</span></label
        >
      </ng-template>
      <div
        (click)="openEditModal()"
        class="inner-absolute-input-value p-inputtext flex flex-column gap-2"
        style="background: transparent; width: calc(100% - (41px + 12rem)); position: absolute; height: 100%"
      >
        <app-principle-list-view
          [data]="getViewData"
          [showModeIcon]="useResponsibilityMode && principleModeSelection == 'SELECTION'"
        ></app-principle-list-view>
      </div>
      <button
        *ngIf="!fControl?.disabled"
        type="button"
        pButton
        (click)="openEditModal()"
        class="white-space-nowrap"
        [icon]="'pi pi-pencil'"
      ></button>
    </span>
    <ng-container *ngIf="control?.dirty">
      <small class="p-error p-invalid" *ngIf="control?.hasError('required')">{{ label }} {{ 'is Required' }}</small>
      <small class="p-error p-invalid" *ngIf="customError && control?.invalid">{{ customErrorLabel }}</small>
    </ng-container>
    <small class="text-500 block" *ngIf="helpText">{{ helpText }}</small>
    <ng-container *ngIf="editModalControl?.dirty">
      <small class="p-error p-invalid" *ngIf="editModalControl?.hasError('required')"
        >{{ label }} {{ 'is Required' }}</small
      >
    </ng-container>
  </div>
</ng-template>

<p-dialog
  [header]="label"
  [(visible)]="editModalVisible"
  [modal]="true"
  [style]="{ width: '80vw' }"
  [draggable]="false"
  [resizable]="true"
>
  <ng-container *ngIf="editModalVisible; then inlineTemplate"></ng-container>
  <div class="w-full flex flex-row justify-content-end">
    <button
      *ngIf="!fControl?.disabled"
      type="button"
      pButton
      (click)="onEditModalSave()"
      label="Ok"
      class="white-space-nowrap w-fit"
      [icon]="'pi pi-pencil'"
    ></button>
  </div>
</p-dialog>
