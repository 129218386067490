import { HttpHeaders } from '@angular/common/http';

export class RequestHandlerOptions {
  params?: any = {};
  showMsg = true;
  showError? = true;
  showLoading = true;
  extras? = null;
  headers?: HttpHeaders = null;
  constructor() {
    this.params = {};
    this.showMsg = false;
    this.showError = true;
    this.showLoading = true;
  }
}
