import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataTypeEnum, GeneratedStatisticDto, IFieldDefinition, TargetTypeEnum } from '@shared/classes';
import { DateInputComponent } from '@shared/components/ui/input-fields/date-input/date-input.component';
import { NumberInputComponent } from '@shared/components/ui/input-fields/number-input/number-input.component';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class GeneratedStatisticMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Label',
      key: 'label',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'label of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
    // {
    //   name: 'Result',
    //   key: 'result',
    //   dataType: DataTypeEnum.Text,
    //   required: false,
    //   showInTable: false,
    //   description: 'Result of the record',
    //   tableDefinition: {
    //     filter: {
    //       type: 'text',
    //       display: 'menu',
    //       matchMode: 'startsWith',
    //       showMatchModes: true,
    //       showAddButton: true,
    //       showOperator: true,
    //     },
    //   },
    // },
    {
      name: 'Collector',
      key: 'collector',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      description: 'Linked Satistic Collector to the record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.StatisticCollector,
        },
      },
    },
    {
      name: 'Filter Version',
      key: 'filterVersion',
      dataType: DataTypeEnum.Number,
      required: false,
      showInTable: true,
      description: 'Filter Version of the record',
      tableDefinition: {
        filter: {
          type: 'numeric',
          display: 'menu',
          matchMode: 'lte',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: NumberInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null),
        },
      },
    },
    {
      name: 'Collection Date',
      key: 'collectionDate',
      dataType: DataTypeEnum.DateLong,
      required: false,
      showInTable: true,
      description: 'Collection Date of the record',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
      formField: {
        componentType: DateInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null),
        },
      },
    },
    {
      name: 'Type',
      key: 'type',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Filter Type of the record',
      translationKeyPrefix: 'general.fields.targetType',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: GeneratedStatisticDto.TypeEnum,
        },
      },
    },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.LongWithHoverText,
      required: false,
      showInTable: false,
      description: 'Description of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
