// Returns {results, height: num}
// Takes {data}
export function parseTree(data, options) {
  return parseGapTree(data);
}

// Returns {results,height}
function parseGapTree(data) {
  const heightAddtion = 50;
  let height = 0;
  const items = [];
  for (let i = 0; i < data?.length; i++) {
    const group = data[i];
    let innerHeight = 0;
    const entityTypesToPush = [];
    let objCount = {
      controlledEntities: 0,
      unControlledEntities: 0
    }
    if (group?.controlledState) {
      const entityTypes = Object.entries(group?.controlledState);
      for (let j = 0; j < entityTypes.length; j++) {
        const entitiyType = entityTypes[j] as any;
        objCount = {
          controlledEntities: objCount.controlledEntities + (entitiyType[1]?.controlledEntities ?? 0),
          unControlledEntities: objCount.unControlledEntities + (entitiyType[1]?.unControlledEntities ?? 0),
        }
        const entitiesToPush = [];
        // const uncontrolledEntitiesToPush = [];

        if (entitiyType[1]?.entities) {
          const entities = Object.entries(entitiyType[1]?.entities);
          for (let k = 0; k < entities.length; k++) {
            innerHeight = innerHeight + (heightAddtion);

            const entity = entities[k] as any;
            entitiesToPush.push(addItem(entity[1]?.code + ': ' + entity[1]?.label,
              entity[1]?.control ?
                [
                  addItem(entity[1]?.control?.code + ': ' + entity[1]?.control?.name,
                    [
                    ],
                    {
                      data: entity[1]?.control?.controlComplianceStatus,
                      type: 'CONTROL'
                    })
                ] : [],
              {
                type: 'ENTITY'
              }));
          }
        }
        entityTypesToPush.push(
          addItem(entitiyType[1]?.type?.code + ': ' + entitiyType[1]?.type?.label,
            entitiesToPush,
            {
              type: 'ENTITY_TYPE',
              data: entitiyType[1]
            }));
      }
    }
    height = height + innerHeight;
    items.push({ item: addItem(group.group?.code + ': ' + group.group?.label, entityTypesToPush, { type: 'SCOPE', data: objCount }), height: innerHeight });
  }

  let cummulativeHeight = height * 0.01;
  height += cummulativeHeight;
  let treeMergeOptions: any = {
    title: {
      text: 'Tree Diagram',
    },
    tooltip: {
      trigger: 'item',
      triggerOn: 'mousemove',
      appendToBody: true,
    },

    series: items.map((x, index) => {
      const top = cummulativeHeight / height * 100;
      cummulativeHeight = cummulativeHeight + x.height;
      const bottom = ((height - cummulativeHeight) / height) * 100;
      return {
        type: 'tree',
        initialTreeDepth: -1,
        animationDuration: 750,
        animationDurationUpdate: 750,
        universalTransition: true,
        roam: true,
        symbolSize: 15,
        data: [x.item],
        top: top + '%',
        bottom: bottom + '%',
        right: '20%',
        emphasis: {
          focus: 'adjacency',
        },
        label: {
          position: 'top',
          formatter: labelFormatter,
        },
        tooltip: {
          formatter: toolTipFormatter,
        },
        draggable: false,
      }
    }),
  };


  return { result: treeMergeOptions, height: Math.max(height, 200) + 'px' };
}

function getColor(status) {
  const badgeColors = MyBadgeColors;
  return badgeColors[status ?? 'NO_RESPONSE'];
}
function addItem(item, children = [], payload = null) {
  const primaryColor = '#1d2643';
  const badgeColors = MyBadgeColors;

  const color = payload.type == 'CONTROL' ? getColor(payload?.data) : (children?.length ? primaryColor : badgeColors['NO_RESPONSE']);

  return {
    name: item,
    payload: payload,
    type: 'node',
    children: children,
    collapsed: false,
    tooltip: {
      borderColor: color,
      position: function (point, params, dom, rect, size) {
        const xOffsetRight = 20; // Offset to the right of the mouse pointer
        const xOffsetLeft = 20; // Offset to the left of the mouse pointer
        const yOffset = 0; // Offset for the vertical position

        // Calculate tooltip position for the right side
        let xPosRight = point[0] + xOffsetRight;
        if (xPosRight + size.contentSize[0] > size.viewSize[0]) {
          xPosRight = size.viewSize[0] - size.contentSize[0] - xOffsetRight;
        }

        // Calculate tooltip position for the left side
        let xPosLeft = point[0] - size.contentSize[0] - xOffsetLeft;
        if (xPosLeft < 0) {
          xPosLeft = xOffsetLeft;
        }

        // Default vertical position
        let yPos = point[1] + yOffset;
        if (yPos + size.contentSize[1] > size.viewSize[1]) {
          yPos = size.viewSize[1] - size.contentSize[1] - yOffset;
        }

        if (payload?.type == 'SCOPE') {
          // Position the tooltip to the right of the mouse
          return [xPosRight, yPos];
        } else if (payload?.type == 'CONTROL') {
          // Position the tooltip to the left of the mouse
          return [xPosLeft, yPos];
        } else {
          return null; // Default behavior for other types
        }
      },
    },
    label: {
      position: payload?.type == 'CONTROL' ? 'right' : 'top',
    },
    itemStyle: {
      color: color,
    },
  };
}
function toolTipFormatter(params) {
  // Customize the tooltip content
  const controlsColor = params?.data?.tooltip?.borderColor;

  if (params?.data?.type == 'link') {
    const name = params?.name;
    const value = params?.value;
    const badgeColors = MyBadgeColors;

    const iconHTML = '<span class="pi pi-arrow-right" style="font-weight: bold;"></span>';
    if (name.length > 100) {
      // Find the first occurrence of '>'
      const index = name.indexOf('>');
      if (index !== -1) {
        // Split the name at the first '>'
        const firstPart = name.substring(0, index + 1); // Including '>'
        const secondPart = name.substring(index + 1);

        // Replace '>' with icon
        const formattedFirstPart = firstPart.replace(/>/g, iconHTML);
        const formattedSecondPart = secondPart.replace(/>/g, iconHTML);

        // Format the parts
        const formattedName = formattedFirstPart + '<br/>' + formattedSecondPart;
        return (
          formattedName +
          '<br/>' +
          '<span style="display: inline-block; margin-right: 4px; border-radius: 50%; width: 10px; height: 10px; background-color: ' +
          controlsColor +
          ';"></span>' +
          '<span style="font-weight: bold;">' +
          'Controls : ' +
          value +
          '</span>'
        );
      }
    }

    // If name length is not greater than 100 or no '>' found, replace all '>' with icon
    const formattedName = name.replace(/>/g, iconHTML);
    return (
      formattedName +
      '<br/>' +
      '<span style="display: inline-block; margin-right: 4px; border-radius: 50%; width: 10px; height: 10px; background-color: ' +
      controlsColor +
      ';"></span>' +
      '<span style="font-weight: bold;">' +
      'Controls : ' +
      value +
      '</span>'
    );
  }
  let content = '<div>';

  const total =
    (params?.data?.payload?.data?.controlledEntities ?? 0) +
    (params?.data?.payload?.data?.unControlledEntities ?? 0);
  if (params?.data?.payload?.type == 'ENTITY')
    return (
      params?.name
    );
  if (params?.data?.payload?.type == 'CONTROL')
    return (
      '<span style="display: inline-block; margin-right: 4px; border-radius: 50%; width: 10px; height: 10px; background-color: ' +
      controlsColor +
      ';"></span>' +
      params?.name
    );
  const controlledCount = params?.data?.payload?.data?.controlledEntities ?? 0;
  const uncontrolledCount = params?.data?.payload?.data?.unControlledEntities ?? 0;

  const controlledPercentage = ((total ? (controlledCount / total) : 0) * 100).toFixed(2);
  const uncontrolledPercentage = ((total ? (uncontrolledCount / total) : 0) * 100).toFixed(2);

  content += '<strong>' + params?.name + '</strong><br/>';

  content +=
    '<span style="display: inline-block; margin-right: 4px; border-radius: 50%; width: 10px; height: 10px; background-color: #EF5350;"></span>';
  content += 'Entities Without Controls: ' + uncontrolledCount + '/' + total + ' (' + uncontrolledPercentage + '%)<br/>';

  content +=
    '<span style="display: inline-block; margin-right: 4px; border-radius: 50%; width: 10px; height: 10px; background-color: #9CCC65;"></span>';
  content += 'Entities With Controls: ' + controlledCount + '/' + total + ' (' + controlledPercentage + '%)<br/>';

  content += '</div>';
  return content;
}

function labelFormatter(params) {
  const len = 40;
  if (params?.name?.length > len + 3) {
    return params?.name?.substring(0, len) + '...';
  } else {
    return params?.name;
  }
}

enum MyBadgeColors {
  'COMPLIANT' = '#9CCC65',
  'NON_COMPLIANT' = '#EF5350',
  'NO_RESPONSE' = '#BDBDBD',
}
