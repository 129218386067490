import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ImportGlobalPackage, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
@Injectable({
  providedIn: 'root',
})
export class ImportGlobalPackageDataService extends BaseRequestControllerService<ImportGlobalPackage> {
  newInstantImportUrl = '/import-global';

  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.hub, '/global-import-packages', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'translations',
      'originalLanguage',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'name',
      'fileId',
      'counts',
      'typesToInclude',
      'packageStatus',
      'errorMessage',
    ]);
  }
  navigateToListPage() {
    this.router.navigateByUrl(`global-package/export-global-package/list`);
  }

  newStartPackageImport(packageCode: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData(this.url + this.newInstantImportUrl, packageCode, options);
  }
}
