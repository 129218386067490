import { Directive, Input } from '@angular/core';
import { UnsubscribeOnDestroyAdapter } from '../controller';

@Directive()
export class BaseViewItem<T = any> extends UnsubscribeOnDestroyAdapter {
  private _data: T;
  get data() {
    return this._data;
  }
  @Input() set data(data: T) {
    this._data = data;
    this.onSetData();
  }
  @Input() translationKeyPrefix: string;
  onSetData() {}
}
