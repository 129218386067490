<app-progressbar-view
  [data]="maxRisk ? ((value || 0) / maxRisk) * 100 : 0"
  [containerStyle]="'margin-bottom: 2px; height:21px;'"
  [onBarValue]="value"
  [tooltipHint]="'Risk Value : ' + value"
  [styleBasedColor]="valueColor"
></app-progressbar-view>
<app-stacked-progress-bar-view
  [data]="progressBarList"
  [containerStyle]="'height:8px; opacity: 0.5'"
></app-stacked-progress-bar-view>
