<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value"
  [dataType]="sunEditorMode ? sunEditorType : dataLongTextType"
  [label]="label"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  @if (showOnFocus) {
    @if (!isInFocus) {
      <app-basic-input
        [label]="showOnFocusLabel"
        [placeholder]="placeholder"
        [control]="control"
        [viewMode]="viewMode"
        (onFocus)="onFocus($event)"
      ></app-basic-input>
    } @else {
      <ng-container *ngTemplateOutlet="focusTemplate"></ng-container>
    }
  } @else {
    <ng-container *ngTemplateOutlet="editorTemplate"></ng-container>
  }
  <ng-template #focusTemplate>
    <ng-container *ngTemplateOutlet="editorTemplate"></ng-container>
    <div class="flex flex-row gap-2">
      <ng-content></ng-content>
      @if (showCancelWithOnFocus) {
        <app-button [action]="cancelFocusAction"></app-button>
      }
    </div>
  </ng-template>
  <ng-template #editorTemplate>
    @if (sunEditorMode) {
      <!-- <app-sun-editor
        [showLabelInFocusMode]="showLabelInFocusMode"
        [height]="height"
        [name]="name"
        [label]="label"
        [placeholder]="placeholder"
        [control]="control"
        [viewMode]="viewMode"
        (onChangeEditorClick)="onChangeEditor()"
      >
        <ng-template #resetCheckTemplate>
          @if (resetFieldCheckVisable) {
            <app-reset-switch-input
              [checked]="!resetable"
              (onChange)="setFieldResetable($event)"
            ></app-reset-switch-input>
          }
        </ng-template>
      </app-sun-editor> -->
    } @else {
      <div
        class="field flex flex-row md:flex-nowrap flex-wrap gap-1 w-full algin-items-start relative"
        [class.hide-toolbar]="hideToolbar"
        [style]="'margin-top:' + marginTop + ';'"
      >
        @if (isDevEnvironment) {
          <app-button class="absolute z-5" style="bottom: 0; right: 24px" [action]="changeEditorAction"></app-button>
        }
        <label
          [for]="name || label"
          class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label hide-children-hover"
          *ngIf="label && showLabelInFocusMode"
          >{{ label }}<span *ngIf="isControlRequired" class="text-red-500"> *</span>
          @if (this.itemCode && viewMode == 'edit') {
            <app-field-translations-button
              [options]="translationOptions"
              class="hidden-item"
            ></app-field-translations-button>
          }
          @if (resetFieldCheckVisable) {
            <br />
            <app-reset-switch-input
              [checked]="!resetable"
              (onChange)="setFieldResetable($event)"
            ></app-reset-switch-input>
          }
          <ng-container *ngIf="control?.dirty && control?.invalid">
            <br />
            <small class="p-error p-invalid" *ngIf="control?.hasError('required')"
              >{{ label }} {{ 'is Required' }}</small
            >
            <small class="p-error p-invalid" *ngIf="control?.hasError('pattern')">{{ label }} {{ patternError }}</small>
            <small class="p-error p-invalid" *ngIf="control?.hasError('minlength')">
              {{ label }} must have a minimum length of {{ control?.errors?.minlength?.requiredLength }}
            </small>
            <small class="p-error p-invalid" *ngIf="control?.hasError('maxlength')">
              {{ label }} must have a maximum length of {{ control?.errors?.maxlength?.requiredLength }}
            </small>
            <small class="p-error p-invalid" *ngIf="control?.hasError('min')">
              {{ label }} must be greater than or equal to {{ control?.errors?.min?.min }}
            </small>
            <small class="p-error p-invalid" *ngIf="control?.hasError('max')">
              {{ label }} must be less than or equal to {{ control?.errors?.max?.max }}
            </small>
            <small class="p-error p-invalid" *ngIf="customError">{{ customErrorLabel }}</small>
          </ng-container>
        </label>
        @if (codeEditorMode) {
          <!-- <div class="flex flex-column w-full p-editor-container">
            <div class="p-editor-toolbar ql-toolbar ql-snow">
              <button
                type="button"
                class="cursor-pointer"
                pTooltip="Editor Mode"
                tooltipPosition="top"
                aria-label="Editor Mode"
                (click)="customFunction()"
              >
                <i class="text-700 text-lg font-bold pi pi-replay"></i>
              </button>
              <ng-container *ngIf="toolbarActions">
                <ng-container *ngTemplateOutlet="toolbarActions; context: { $implicit: editor }"></ng-container>
              </ng-container>
            </div>
  
            <app-monaco-editor-input
              class="w-full"
              [control]="fControl"
              [height]="editor?.el?.nativeElement?.offsetHeight - 84 + 'px'"
              [style]="{ height: editor?.el?.nativeElement?.offsetHeight - 84 + 'px', width: '100%' }"
            ></app-monaco-editor-input>
          </div> -->
        } @else {
          @if (fControl.enabled) {
            <app-kendo-editor
              [fControl]="fControl"
              [placeholder]="placeholder"
              [advanced]="advanced"
              [customDropdownData]="customDropdownData"
              (onCustomDropdownOpen)="onCustomDropdownOpenAction($event)"
            >
            </app-kendo-editor>
          } @else {
            <app-kendo-editor
              [fControl]="fControl"
              [placeholder]="' '"
              [advanced]="advanced"
              [customDropdownData]="customDropdownData"
              (onCustomDropdownOpen)="onCustomDropdownOpenAction($event)"
            >
            </app-kendo-editor>
          }
          @if (showOldEditor) {
            <!-- <p-editor
              class="w-full"
              #editor
              [placeholder]="placeholder"
              [formControl]="fControl"
              [style]="{ height: height, width: '100%', 'overflow-y': 'auto', resize: 'vertical', minHeight: '6rem' }"
              [readonly]="fControl.disabled"
              (resize)="onResize($event)"
            >
              <ng-template pTemplate="header">
                <ng-container *ngIf="advanced; else elseTemplate">
                  <span class="ql-formats">
                    <select class="ql-size" aria-label="Text Size"></select>
                    <select class="ql-font"></select>
                  </span>
                  <span class="ql-formats">
                    <button type="button" class="ql-bold" aria-label="Bold"></button>
                    <button type="button" class="ql-italic" aria-label="Italic"></button>
                    <button type="button" class="ql-underline" aria-label="Underline"></button>
                    <button type="button" class="ql-strike" aria-label="Strikethrough"></button>
                  </span>
                  <span class="ql-formats">
                    <button type="button" class="ql-code-block" aria-label="Code Block"></button>
                    <button type="button" class="ql-blockquote" aria-label="Block Quote"></button>
                  </span>
                  <span class="ql-formats">
                    <button type="button" value="bullet" class="ql-list" aria-label="List Bullet"></button>
                    <button type="button" value="ordered" class="ql-list" aria-label="List Ordered"></button>
                  </span>
                  <span class="ql-formats">
                    <select class="ql-align" aria-label="Text Alignment"></select>
                    <button type="button" value="-1" class="ql-indent" aria-label="Minus Indent"></button>
                    <button type="button" value="+1" class="ql-indent" aria-label="Plus Indent"></button>
                    <button type="button" value="rtl" class="ql-direction" aria-label="Text Direction"></button>
                  </span>
                  <span class="ql-formats">
                    <button type="button" value="sub" class="ql-script" aria-label="Sub Script"></button>
                    <button type="button" value="super" class="ql-script" aria-label="Super Script"></button>
                  </span>
                  <span class="ql-formats">
                    <select class="ql-color" aria-label="Text Color"></select>
                    <select class="ql-background" aria-label="Text Background"></select>
                  </span>
                  <span class="ql-formats">
                    <button type="button" class="ql-link" aria-label="Add Link"></button>
                    <button type="button" class="ql-image" aria-label="Add Image"></button>
                    <button type="button" class="ql-clean" aria-label="Remove Formatting"></button>
                    <button
                      type="button"
                      class=""
                      pTooltip="Edit Source"
                      tooltipPosition="top"
                      aria-label="Edit Source"
                      (click)="customFunction()"
                    >
                      <i class="text-700 text-lg font-bold pi pi-code"></i>
                    </button>
                    <ng-container *ngIf="toolbarActions">
                      <ng-container *ngTemplateOutlet="toolbarActions; context: { $implicit: editor }"></ng-container>
                    </ng-container>
                  </span>
                </ng-container>
                <ng-template #elseTemplate>
                  <span class="ql-formats">
                    <button type="button" class="ql-bold" aria-label="Bold"></button>
                    <button type="button" class="ql-italic" aria-label="Italic"></button>
                    <button type="button" class="ql-underline" aria-label="Underline"></button>
                    <button type="button" class="ql-strike" aria-label="Strikethrough"></button>
                  </span>
                  <span class="ql-formats">
                    <button type="button" value="bullet" class="ql-list" aria-label="List Bullet"></button>
                    <button type="button" value="ordered" class="ql-list" aria-label="List Ordered"></button>
                  </span>
                  <span class="ql-formats">
                    <select class="ql-align" aria-label="Text Alignment"></select>
                  </span>
                  <span class="ql-formats">
                    <button type="button" class="ql-clean" aria-label="Remove Formatting"></button>
                  </span>
                </ng-template>
              </ng-template>
            </p-editor> -->
          }
        }
        <!-- <ckeditor [editor]="Editor" [formControl]="fControl" (ready)="onReady($event)"></ckeditor> -->
      </div>
    }
  </ng-template>
</app-input-view-switch>
