/**
 * Sightflare Service
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AggregationField { 
    operation?: AggregationField.OperationEnum;
    fieldName?: string;
}
export namespace AggregationField {
    export type OperationEnum = 'AVG' | 'SUM' | 'MAX' | 'MIN' | 'FIRST' | 'LAST' | 'COUNT';
    export const OperationEnum = {
        Avg: 'AVG' as OperationEnum,
        Sum: 'SUM' as OperationEnum,
        Max: 'MAX' as OperationEnum,
        Min: 'MIN' as OperationEnum,
        First: 'FIRST' as OperationEnum,
        Last: 'LAST' as OperationEnum,
        Count: 'COUNT' as OperationEnum
    };
}


