import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DataTypeEnum, IFieldDefinition } from '@shared/classes';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { PSliderInputComponent } from '@shared/components/ui/input-fields/p-slider-input/p-slider-input.component';
import { SwitchInputComponent } from '@shared/components/ui/input-fields/switch-input/switch-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter Name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Type',
      key: 'type',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      description: 'Type of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
      },
      useInBulk: true,
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Items',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['QUESTIONNAIRE_TYPE'],
            },
            dropDownInput: {
              multi: true,
              items: null,
              customProjectionFields: [
                'id',
                'question',
                'code',
                'recordStatus',
                'fields',
                'fieldDtos',
                'options',
                'label',
              ],
              optionLabel: 'name',
              optionValue: 'code'
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Scored Questionnaire',
      key: 'scoredQuestionnaire',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: false,
      useInBulk: true,
      formField: {
        componentType: SwitchInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null),
        },
      },
    },
    {
      name: 'Passing Score',
      key: 'passScore',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: false,
      formField: {
        componentType: PSliderInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null),
        },
      },
    },
    {
      name: 'Questions',
      key: 'questions',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: false,
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Items',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['QUESTION'],
            },
            dropDownInput: {
              multi: true,
              items: null,
              customProjectionFields: [
                'id',
                'question',
                'code',
                'recordStatus',
                'fields',
                'fieldDtos',
                'options',
                'label',
              ],
              optionLabel: 'name',
              optionValue: 'code'
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.LongWithHoverText,
      required: true,
      showInTable: false,
      description: 'Record Description',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: TextEditorComponent,
        options: {
          inputOptions: {
            textEditorInput: {
              advanced: true
            }
          },
          label: '',
          name: '',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },

    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
