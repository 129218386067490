const dockerEnvironment = window && window['injectedEnv'] ? window['injectedEnv'] : {};
export const environment = {
  production: dockerEnvironment.NG_APP_PRODUCTION,
  useLocalLocale: false,
  apiBaseUrl: dockerEnvironment.NG_APP_API_BASE_URL,
  websiteUrl: dockerEnvironment.NG_APP_WEBSITE_URL,
  logoutRedirectUrl: dockerEnvironment.NG_APP_LOGOUT_REDIRECT_URL,
  authUrl: dockerEnvironment.NG_APP_AUTH_URL,

  fileManager:
    (dockerEnvironment.NG_APP_FILE_MANAGER_BASE_URL || dockerEnvironment.NG_APP_API_BASE_URL) +
    dockerEnvironment.NG_APP_FILE_MANAGER,
  user:
    (dockerEnvironment.NG_APP_USER_BASE_URL || dockerEnvironment.NG_APP_API_BASE_URL) + dockerEnvironment.NG_APP_USER,
  role:
    (dockerEnvironment.NG_APP_ROLE_BASE_URL || dockerEnvironment.NG_APP_API_BASE_URL) + dockerEnvironment.NG_APP_ROLE,
  framework:
    (dockerEnvironment.NG_APP_FRAMEWORK_BASE_URL || dockerEnvironment.NG_APP_API_BASE_URL) +
    dockerEnvironment.NG_APP_FRAMEWORK,
  userOperations:
    (dockerEnvironment.NG_APP_USER_OPERATIONS_BASE_URL || dockerEnvironment.NG_APP_API_BASE_URL) +
    dockerEnvironment.NG_APP_USER_OPERATIONS,
  risk:
    (dockerEnvironment.NG_APP_RISK_BASE_URL || dockerEnvironment.NG_APP_API_BASE_URL) + dockerEnvironment.NG_APP_RISK,
  questions:
    (dockerEnvironment.NG_APP_QUESTIONS_BASE_URL || dockerEnvironment.NG_APP_API_BASE_URL) +
    dockerEnvironment.NG_APP_QUESTIONS,
  relation:
    (dockerEnvironment.NG_APP_RELATION_BASE_URL || dockerEnvironment.NG_APP_API_BASE_URL) +
    dockerEnvironment.NG_APP_RELATION,
  entity:
    (dockerEnvironment.NG_APP_ENTITY_BASE_URL || dockerEnvironment.NG_APP_API_BASE_URL) +
    dockerEnvironment.NG_APP_ENTITY,
  authority:
    (dockerEnvironment.NG_APP_AUTHORITY_BASE_URL || dockerEnvironment.NG_APP_API_BASE_URL) +
    dockerEnvironment.NG_APP_AUTHORITY,
  acknowledgement:
    (dockerEnvironment.NG_APP_ACKNOWLEDGEMENT_BASE_URL || dockerEnvironment.NG_APP_API_BASE_URL) +
    dockerEnvironment.NG_APP_ACKNOWLEDGEMENT,
  issue:
    (dockerEnvironment.NG_APP_ISSUE_BASE_URL || dockerEnvironment.NG_APP_API_BASE_URL) + dockerEnvironment.NG_APP_ISSUE,
  configuration:
    (dockerEnvironment.NG_APP_CONFIGURATION_BASE_URL || dockerEnvironment.NG_APP_API_BASE_URL) +
    dockerEnvironment.NG_APP_CONFIGURATION,
  dataStructure:
    (dockerEnvironment.NG_APP_DATA_STRUCTURE_BASE_URL || dockerEnvironment.NG_APP_API_BASE_URL) +
    dockerEnvironment.NG_APP_DATA_STRUCTURE,
  hub: (dockerEnvironment.NG_APP_HUB_BASE_URL || dockerEnvironment.NG_APP_API_BASE_URL) + dockerEnvironment.NG_APP_HUB,
  audit:
    (dockerEnvironment.NG_APP_AUDIT_BASE_URL || dockerEnvironment.NG_APP_API_BASE_URL) + dockerEnvironment.NG_APP_AUDIT,
  transparencyManagement:
    (dockerEnvironment.NG_APP_TM_BASE_URL || dockerEnvironment.NG_APP_API_BASE_URL) + dockerEnvironment.NG_APP_TM,
  performance:
    (dockerEnvironment.NG_APP_PERFORMANCE_BASE_URL || dockerEnvironment.NG_APP_API_BASE_URL) +
    dockerEnvironment.NG_APP_PERFORMANCE,
  bcm: (dockerEnvironment.NG_APP_BCM_BASE_URL || dockerEnvironment.NG_APP_API_BASE_URL) + dockerEnvironment.NG_APP_BCM,

  keyCloak: {
    config: {
      url: dockerEnvironment.NG_APP_AUTH_URL,
      realm: dockerEnvironment.NG_APP_KEYCLOAK_REALM,
      clientId: dockerEnvironment.NG_APP_KEYCLOAK_CLIENT_ID,
    },
    initOptions: {
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
    },
  },
};
