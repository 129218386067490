/**
 * Sightflare Service
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SortField { 
    fieldName?: string;
    sortOperation?: SortField.SortOperationEnum;
    nulls?: SortField.NullsEnum;
}
export namespace SortField {
    export type SortOperationEnum = 'ASC' | 'DESC';
    export const SortOperationEnum = {
        Asc: 'ASC' as SortOperationEnum,
        Desc: 'DESC' as SortOperationEnum
    };
    export type NullsEnum = 'NATIVE' | 'FIRST' | 'LAST';
    export const NullsEnum = {
        Native: 'NATIVE' as NullsEnum,
        First: 'FIRST' as NullsEnum,
        Last: 'LAST' as NullsEnum
    };
}


