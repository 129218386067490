/**
 * Sightflare Service
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AccessRecord { 
    creatorName?: string;
    creationDate?: string;
    principle?: string;
    principleType?: AccessRecord.PrincipleTypeEnum;
    accessLevel?: AccessRecord.AccessLevelEnum;
    accessType?: AccessRecord.AccessTypeEnum;
    settingsAccessRecordUuid?: string;
    referenceValue?: string;
    referenceName?: string;
}
export namespace AccessRecord {
    export type PrincipleTypeEnum = 'USER' | 'RESPONSIBILITY' | 'GROUP';
    export const PrincipleTypeEnum = {
        User: 'USER' as PrincipleTypeEnum,
        Responsibility: 'RESPONSIBILITY' as PrincipleTypeEnum,
        Group: 'GROUP' as PrincipleTypeEnum
    };
    export type AccessLevelEnum = 'ADMIN' | 'OWNER' | 'UPDATE' | 'READ';
    export const AccessLevelEnum = {
        Admin: 'ADMIN' as AccessLevelEnum,
        Owner: 'OWNER' as AccessLevelEnum,
        Update: 'UPDATE' as AccessLevelEnum,
        Read: 'READ' as AccessLevelEnum
    };
    export type AccessTypeEnum = 'DEFAULT_DIRECT' | 'DEFAULT_FIELDS_BASED' | 'DEFAULT_INHERITED' | 'DEFAULT_OWNER' | 'MANUAL';
    export const AccessTypeEnum = {
        DefaultDirect: 'DEFAULT_DIRECT' as AccessTypeEnum,
        DefaultFieldsBased: 'DEFAULT_FIELDS_BASED' as AccessTypeEnum,
        DefaultInherited: 'DEFAULT_INHERITED' as AccessTypeEnum,
        DefaultOwner: 'DEFAULT_OWNER' as AccessTypeEnum,
        Manual: 'MANUAL' as AccessTypeEnum
    };
}


