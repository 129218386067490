/**
 * Sightflare Service
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CodesToExportItem } from './codesToExportItem';
import { RelationExportDto } from './relationExportDto';


export interface StringifiedExportItemClean { 
    uuid?: string;
    stringefiedExport?: string;
    toBeExported?: Array<CodesToExportItem>;
    resultRelations?: Array<RelationExportDto>;
    moduleName?: StringifiedExportItemClean.ModuleNameEnum;
    addOrder?: number;
    autoDependencies?: Array<string>;
    notAutoDependencies?: Array<string>;
}
export namespace StringifiedExportItemClean {
    export type ModuleNameEnum = 'ALL' | 'DOCUMENT_APP' | 'ACKNOWLEDGMENT_APP' | 'ADMIN_APP' | 'CONFIG_APP' | 'DATA_STRUCTURE_APP' | 'BRANDING_APP' | 'ENTITY_APP' | 'FRAMEWORK_APP' | 'ISSUES_APP' | 'QUESTION_APP' | 'RELATION_APP' | 'RISK_APP' | 'USER_APP' | 'USER_OPERATIONS_APP' | 'AUDIT_APP' | 'HUB_APP' | 'TRANSPARENCY_APP' | 'PERFORMANCE_APP' | 'BCM_APP';
    export const ModuleNameEnum = {
        All: 'ALL' as ModuleNameEnum,
        DocumentApp: 'DOCUMENT_APP' as ModuleNameEnum,
        AcknowledgmentApp: 'ACKNOWLEDGMENT_APP' as ModuleNameEnum,
        AdminApp: 'ADMIN_APP' as ModuleNameEnum,
        ConfigApp: 'CONFIG_APP' as ModuleNameEnum,
        DataStructureApp: 'DATA_STRUCTURE_APP' as ModuleNameEnum,
        BrandingApp: 'BRANDING_APP' as ModuleNameEnum,
        EntityApp: 'ENTITY_APP' as ModuleNameEnum,
        FrameworkApp: 'FRAMEWORK_APP' as ModuleNameEnum,
        IssuesApp: 'ISSUES_APP' as ModuleNameEnum,
        QuestionApp: 'QUESTION_APP' as ModuleNameEnum,
        RelationApp: 'RELATION_APP' as ModuleNameEnum,
        RiskApp: 'RISK_APP' as ModuleNameEnum,
        UserApp: 'USER_APP' as ModuleNameEnum,
        UserOperationsApp: 'USER_OPERATIONS_APP' as ModuleNameEnum,
        AuditApp: 'AUDIT_APP' as ModuleNameEnum,
        HubApp: 'HUB_APP' as ModuleNameEnum,
        TransparencyApp: 'TRANSPARENCY_APP' as ModuleNameEnum,
        PerformanceApp: 'PERFORMANCE_APP' as ModuleNameEnum,
        BcmApp: 'BCM_APP' as ModuleNameEnum
    };
}


