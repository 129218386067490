import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[appMaxCharacterValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: appMaxCharacterValidator,
      multi: true
    }
  ]
})
export class appMaxCharacterValidator implements Validator {
  @Input('appMaxTextLength') maxLength = 2000;

  private control;
  constructor(private renderer: Renderer2, private el: ElementRef) { }

  @HostListener('input', ['$event.target'])
  onInput(target: HTMLInputElement | HTMLTextAreaElement) {
    if (!this.control && target.value.length > this.maxLength) {
      target.value = target.value.substring(0, this.maxLength); // Truncate to maxLength
      this.renderer.setProperty(this.el.nativeElement, 'value', target.value); // Update the DOM value
    }
  }
  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.length > this.maxLength) {
      this.control = control;
      return { maxlength: { requiredLength: this.maxLength } };
    }
    return null;
  }
}